/* JADE TinyMCE Styles */
.BambooRichText {
	// .BhrPage-wrap applies text-align: center globally for "reasons"? So we get to do dumb stuff like this.
	+encore() {
		text-align: left;
	}
	&--error {
		& ^[0]__wrapper {
			fab-border-color: danger;

			&--focused {
				border: 1px solid fab-color(theme-lighter);
				border: 1px solid var(--fabric-theme-lighter, $defaultTheme.lighter);
				box-shadow: 0 0 0 2px fab-color(theme-lighter);
				box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba($defaultTheme.lightest, 0.35));
			}
		}
	}

	&__wrapper {
		position: relative;
		border: 1px solid fab-color(white);
		border-radius: 2px;
		+encore() {
			border-radius: var(--radius-200);
		}

		&--borderTop {
			border-top-color: fab-color(gray3);
		}

		&--border {
			border-top-color: fab-color(gray3);
			border-bottom-color: fab-color(gray3);
		}

		&--borderAll {
			border-color: fab-color(gray3);
		}

		// In some instance we use a plugin `ui-src/public/_mce_plugins/common/index.js` to manage the focus state.
		&--focused {
			border: 1px solid fab-color(theme-lighter);
			border: 1px solid var(--fabric-theme-lighter, $defaultTheme.lighter);
			box-shadow: 0 0 0 2px fab-color(theme-lighter);
			box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba($defaultTheme.lightest, 0.35));
			outline: 0;
			z-index: 1;
		}

		^[0]:not(^[0]--error) &:hover:not(&--focused) {
			border: 1px solid fab-color(gray4);
			box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
		}
	}
	// matches .fab-Textarea styles
	&__editor {
		position: relative;
		padding: 0 20px 20px;
		background-color: fab-color(white);
		box-sizing: border-box;
		fab-color: gray10;
		fab-font-size: medium;
		font-family: "Lato", sans-serif;
		height: auto;
		min-height: 250px;
		z-index: 0;
		transition: all 200ms ease-in-out;
		padding-top: 1em;
		+encore() {
			font-family: "Inter", sans-serif;
			border-radius: var(--radius-200);
			padding: 9px 16px 0px 16px;
			font-weight: 400;
		}

		&--compact {
			min-height: 55px;
			padding: 16px 20px;
			font-weight: 600;

			+encore() {
				min-height: 40px;
				padding: 9px 16px;
				font-weight: 400;
			}
		}

		&--files {
			padding: 0 20px 20px;
		}

		&--borderBottom {
			border-bottom-color: fab-color(gray3);
		}

		// TODO: we need an accessible solution, but this is how we're doing it before Jade, too
		&--placeholder {
			// The Subject Editor doen't wrap it's content in <p> tags and in IE an empty content editable div
			// and the Puesdo elements line height conflict, causing the placeholder to be mispositioned.
			&[contentEditable=true]:empty {
				&:before {
					line-height: 0;
					top: 27px;
				}
			}

			&:before {
				content: attr(placeholder);
				fab-color: gray6;
				fab-text: medium;
				position: absolute;
			}
		}
	}

	&__toolbar {
		padding-bottom: 12px;
	}

	$lineHeight = 1.4;
	$lineHeightEncore = 1.5;
	line-height: $lineHeight;
	+encore() {
		line-height: $lineHeightEncore;
	}

	// Content specific Styles
	& p {
		margin: 0;
		// for PDF
		-webkit-margin-after: 0;
		-webkit-margin-start: 0;
		-webkit-margin-end: 0;

		&:not([hidden]) {
			display: block;
		}
	}

	// Over-ride for legacy bold.
	& strong {
		font-weight: 700;
	}

	& ul, & ol {
		display: block;
		list-style-position: outside !important;
		margin-left: 30px !important;
	}

	// override reset.styl back to user-agent styles
	& ul {
		list-style-type: disc;

		& ul {
			list-style-type: circle;

			& ul {
				list-style-type: square;
			}
		}
	}

	& ol {
		list-style-type: decimal;

		& ol {
			list-style-type: lower-alpha;

			& ol {
				list-style-type: lower-roman;
			}
		}
	}

	& li ul, & li ol {
		margin-top: 5px !important; // override margin-top from above without benefit of specific classes
	}

	& li {
		display: list-item;
		text-align: -webkit-match-parent;
		margin-bottom: 5px;
		line-height: $lineHeight !important;
		+encore() {
			line-height: $lineHeightEncore !important;

		}
	}

	& img.placeholder {
		height: 18px;
		margin: 0 1px 1px;
		vertical-align: bottom;
		+encore() {
			height: 22px;
			margin: 0 3px 1px;
			vertical-align: middle;
		}
		&[data-mce-selected] {
			+encore() {
				outline: none;
				border: none;
				border-radius: 5px;
				box-shadow: 0 0 0pt 1px fab-color(gray10);
			}
		}
		// removes the browsers default text highlighting from the placeholders themselves.
		&::-moz-selection { background-color: transparent; }
		&::selection { background-color: transparent; background: transparent; }

	}
}


// Need to circle back to this. The subject editor get's a useless absolute positioned menu plcaeholder, it's empty but this menu happens to have a border-width of 1px, causing a 1px dot,
// that luky enough for us, is positioned directly on the corner of the subject editor, causing the focus state to look like it's got a notch.
.mce-tinymce-inline.mce-floatpanel {
	border: none !important;
}
