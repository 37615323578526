[ba-theme="lime1"] {
  --fabric-theme-base: #527a00;
  --fabric-theme-light: #6a950a;
  --fabric-theme-lighter: #82af13;
  --fabric-theme-lightest: #c0e94f;
  --fabric-theme-lightest10: rgba(192,233,79,0.1);
  --fabric-theme-lightest35: rgba(192,233,79,0.35);
  --fabric-theme-lightest50: rgba(192,233,79,0.5);
}
[ba-theme="lime2"] {
  --fabric-theme-base: #456e00;
  --fabric-theme-light: #5e8500;
  --fabric-theme-lighter: #769c00;
  --fabric-theme-lightest: #a8cd42;
  --fabric-theme-lightest10: rgba(168,205,66,0.1);
  --fabric-theme-lightest35: rgba(168,205,66,0.35);
  --fabric-theme-lightest50: rgba(168,205,66,0.5);
}
[ba-theme="lime3"] {
  --fabric-theme-base: #365900;
  --fabric-theme-light: #4e7000;
  --fabric-theme-lighter: #658600;
  --fabric-theme-lightest: #96b63d;
  --fabric-theme-lightest10: rgba(150,182,61,0.1);
  --fabric-theme-lightest35: rgba(150,182,61,0.35);
  --fabric-theme-lightest50: rgba(150,182,61,0.5);
}
[ba-theme="lime4"] {
  --fabric-theme-base: #2b4900;
  --fabric-theme-light: #425f01;
  --fabric-theme-lighter: #587501;
  --fabric-theme-lightest: #88a43a;
  --fabric-theme-lightest10: rgba(136,164,58,0.1);
  --fabric-theme-lightest35: rgba(136,164,58,0.35);
  --fabric-theme-lightest50: rgba(136,164,58,0.5);
}
[ba-theme="green1"] {
  --fabric-theme-base: #188124;
  --fabric-theme-light: #39a33e;
  --fabric-theme-lighter: #59c457;
  --fabric-theme-lightest: #8df886;
  --fabric-theme-lightest10: rgba(141,248,134,0.1);
  --fabric-theme-lightest35: rgba(141,248,134,0.35);
  --fabric-theme-lightest50: rgba(141,248,134,0.5);
}
[ba-theme="green2"] {
  --fabric-theme-base: #007400;
  --fabric-theme-light: #1e901c;
  --fabric-theme-lighter: #3cab38;
  --fabric-theme-lightest: #73de67;
  --fabric-theme-lightest10: rgba(115,222,103,0.1);
  --fabric-theme-lightest35: rgba(115,222,103,0.35);
  --fabric-theme-lightest50: rgba(115,222,103,0.5);
}
[ba-theme="green3"] {
  --fabric-theme-base: #005d00;
  --fabric-theme-light: #187416;
  --fabric-theme-lighter: #308b2c;
  --fabric-theme-lightest: #64bc59;
  --fabric-theme-lightest10: rgba(100,188,89,0.1);
  --fabric-theme-lightest35: rgba(100,188,89,0.35);
  --fabric-theme-lightest50: rgba(100,188,89,0.5);
}
[ba-theme="green4"] {
  --fabric-theme-base: #004900;
  --fabric-theme-light: #056004;
  --fabric-theme-lighter: #0a7607;
  --fabric-theme-lightest: #4ba63c;
  --fabric-theme-lightest10: rgba(75,166,60,0.1);
  --fabric-theme-lightest35: rgba(75,166,60,0.35);
  --fabric-theme-lightest50: rgba(75,166,60,0.5);
}
[ba-theme="teal1"] {
  --fabric-theme-base: #017971;
  --fabric-theme-light: #289990;
  --fabric-theme-lighter: #4eb8af;
  --fabric-theme-lightest: #83ebe1;
  --fabric-theme-lightest10: rgba(131,235,225,0.1);
  --fabric-theme-lightest35: rgba(131,235,225,0.35);
  --fabric-theme-lightest50: rgba(131,235,225,0.5);
}
[ba-theme="teal2"] {
  --fabric-theme-base: #006f67;
  --fabric-theme-light: #1c877e;
  --fabric-theme-lighter: #389e95;
  --fabric-theme-lightest: #6ecfc5;
  --fabric-theme-lightest10: rgba(110,207,197,0.1);
  --fabric-theme-lightest35: rgba(110,207,197,0.35);
  --fabric-theme-lightest50: rgba(110,207,197,0.5);
}
[ba-theme="teal3"] {
  --fabric-theme-base: #005d56;
  --fabric-theme-light: #14746c;
  --fabric-theme-lighter: #288b82;
  --fabric-theme-lightest: #5fbbb1;
  --fabric-theme-lightest10: rgba(95,187,177,0.1);
  --fabric-theme-lightest35: rgba(95,187,177,0.35);
  --fabric-theme-lightest50: rgba(95,187,177,0.5);
}
[ba-theme="teal4"] {
  --fabric-theme-base: #004c45;
  --fabric-theme-light: #0b635b;
  --fabric-theme-lighter: #167970;
  --fabric-theme-lightest: #50a89e;
  --fabric-theme-lightest10: rgba(80,168,158,0.1);
  --fabric-theme-lightest35: rgba(80,168,158,0.35);
  --fabric-theme-lightest50: rgba(80,168,158,0.5);
}
[ba-theme="blue1"] {
  --fabric-theme-base: #0878a1;
  --fabric-theme-light: #2f94bf;
  --fabric-theme-lighter: #56afdd;
  --fabric-theme-lightest: #8ce1ff;
  --fabric-theme-lightest10: rgba(140,225,255,0.1);
  --fabric-theme-lightest35: rgba(140,225,255,0.35);
  --fabric-theme-lightest50: rgba(140,225,255,0.5);
}
[ba-theme="blue2"] {
  --fabric-theme-base: #00658e;
  --fabric-theme-light: #107ca6;
  --fabric-theme-lighter: #2093be;
  --fabric-theme-lightest: #63c3f1;
  --fabric-theme-lightest10: rgba(99,195,241,0.1);
  --fabric-theme-lightest35: rgba(99,195,241,0.35);
  --fabric-theme-lightest50: rgba(99,195,241,0.5);
}
[ba-theme="blue3"] {
  --fabric-theme-base: #00558b;
  --fabric-theme-light: #0f6ba3;
  --fabric-theme-lighter: #1d81bb;
  --fabric-theme-lightest: #60b0ee;
  --fabric-theme-lightest10: rgba(96,176,238,0.1);
  --fabric-theme-lightest35: rgba(96,176,238,0.35);
  --fabric-theme-lightest50: rgba(96,176,238,0.5);
}
[ba-theme="blue4"] {
  --fabric-theme-base: #003e63;
  --fabric-theme-light: #00547a;
  --fabric-theme-lighter: #006991;
  --fabric-theme-lightest: #4c97c2;
  --fabric-theme-lightest10: rgba(76,151,194,0.1);
  --fabric-theme-lightest35: rgba(76,151,194,0.35);
  --fabric-theme-lightest50: rgba(76,151,194,0.5);
}
[ba-theme="violet1"] {
  --fabric-theme-base: #4968a9;
  --fabric-theme-light: #627fc2;
  --fabric-theme-lighter: #7b96db;
  --fabric-theme-lightest: #adc6ff;
  --fabric-theme-lightest10: rgba(173,198,255,0.1);
  --fabric-theme-lightest35: rgba(173,198,255,0.35);
  --fabric-theme-lightest50: rgba(173,198,255,0.5);
}
[ba-theme="violet2"] {
  --fabric-theme-base: #2f579f;
  --fabric-theme-light: #4a6db8;
  --fabric-theme-lighter: #6583d1;
  --fabric-theme-lightest: #98b2ff;
  --fabric-theme-lightest10: rgba(152,178,255,0.1);
  --fabric-theme-lightest35: rgba(152,178,255,0.35);
  --fabric-theme-lightest50: rgba(152,178,255,0.5);
}
[ba-theme="violet3"] {
  --fabric-theme-base: #1e488d;
  --fabric-theme-light: #3a5ea6;
  --fabric-theme-lighter: #5673be;
  --fabric-theme-lightest: #89a1f1;
  --fabric-theme-lightest10: rgba(137,161,241,0.1);
  --fabric-theme-lightest35: rgba(137,161,241,0.35);
  --fabric-theme-lightest50: rgba(137,161,241,0.5);
}
[ba-theme="violet4"] {
  --fabric-theme-base: #05397b;
  --fabric-theme-light: #264e93;
  --fabric-theme-lighter: #4763ab;
  --fabric-theme-lightest: #7990dd;
  --fabric-theme-lightest10: rgba(121,144,221,0.1);
  --fabric-theme-lightest35: rgba(121,144,221,0.35);
  --fabric-theme-lightest50: rgba(121,144,221,0.5);
}
[ba-theme="purple1"] {
  --fabric-theme-base: #8057a8;
  --fabric-theme-light: #996ec1;
  --fabric-theme-lighter: #b185da;
  --fabric-theme-lightest: #e4b5ff;
  --fabric-theme-lightest10: rgba(228,181,255,0.1);
  --fabric-theme-lightest35: rgba(228,181,255,0.35);
  --fabric-theme-lightest50: rgba(228,181,255,0.5);
}
[ba-theme="purple2"] {
  --fabric-theme-base: #6a4197;
  --fabric-theme-light: #8257b0;
  --fabric-theme-lighter: #9a6dc8;
  --fabric-theme-lightest: #cc9cfb;
  --fabric-theme-lightest10: rgba(204,156,251,0.1);
  --fabric-theme-lightest35: rgba(204,156,251,0.35);
  --fabric-theme-lightest50: rgba(204,156,251,0.5);
}
[ba-theme="purple3"] {
  --fabric-theme-base: #582374;
  --fabric-theme-light: #70398c;
  --fabric-theme-lighter: #874fa3;
  --fabric-theme-lightest: #b87dd5;
  --fabric-theme-lightest10: rgba(184,125,213,0.1);
  --fabric-theme-lightest35: rgba(184,125,213,0.35);
  --fabric-theme-lightest50: rgba(184,125,213,0.5);
}
[ba-theme="purple4"] {
  --fabric-theme-base: #44195b;
  --fabric-theme-light: #5b2e72;
  --fabric-theme-lighter: #714388;
  --fabric-theme-lightest: #a16fb8;
  --fabric-theme-lightest10: rgba(161,111,184,0.1);
  --fabric-theme-lightest35: rgba(161,111,184,0.35);
  --fabric-theme-lightest50: rgba(161,111,184,0.5);
}
[ba-theme="pink1"] {
  --fabric-theme-base: #b14b79;
  --fabric-theme-light: #cb6391;
  --fabric-theme-lighter: #e57ba8;
  --fabric-theme-lightest: #ffacd9;
  --fabric-theme-lightest10: rgba(255,172,217,0.1);
  --fabric-theme-lightest35: rgba(255,172,217,0.35);
  --fabric-theme-lightest50: rgba(255,172,217,0.5);
}
[ba-theme="pink2"] {
  --fabric-theme-base: #9b1f5c;
  --fabric-theme-light: #b63a73;
  --fabric-theme-lighter: #d05489;
  --fabric-theme-lightest: #ff85b9;
  --fabric-theme-lightest10: rgba(255,133,185,0.1);
  --fabric-theme-lightest35: rgba(255,133,185,0.35);
  --fabric-theme-lightest50: rgba(255,133,185,0.5);
}
[ba-theme="pink3"] {
  --fabric-theme-base: #9e0057;
  --fabric-theme-light: #b91a6e;
  --fabric-theme-lighter: #d43384;
  --fabric-theme-lightest: #ff6ab4;
  --fabric-theme-lightest10: rgba(255,106,180,0.1);
  --fabric-theme-lightest35: rgba(255,106,180,0.35);
  --fabric-theme-lightest50: rgba(255,106,180,0.5);
}
[ba-theme="pink4"] {
  --fabric-theme-base: #70003f;
  --fabric-theme-light: #8a1355;
  --fabric-theme-lighter: #a3256a;
  --fabric-theme-lightest: #d85998;
  --fabric-theme-lightest10: rgba(216,89,152,0.1);
  --fabric-theme-lightest35: rgba(216,89,152,0.35);
  --fabric-theme-lightest50: rgba(216,89,152,0.5);
}
[ba-theme="red1"] {
  --fabric-theme-base: #bc363d;
  --fabric-theme-light: #d84f53;
  --fabric-theme-lighter: #f46868;
  --fabric-theme-lightest: #ff9a96;
  --fabric-theme-lightest10: rgba(255,154,150,0.1);
  --fabric-theme-lightest35: rgba(255,154,150,0.35);
  --fabric-theme-lightest50: rgba(255,154,150,0.5);
}
[ba-theme="red2"] {
  --fabric-theme-base: #af0025;
  --fabric-theme-light: #cc2339;
  --fabric-theme-lighter: #e8454d;
  --fabric-theme-lightest: #ff7979;
  --fabric-theme-lightest10: rgba(255,121,121,0.1);
  --fabric-theme-lightest35: rgba(255,121,121,0.35);
  --fabric-theme-lightest50: rgba(255,121,121,0.5);
}
[ba-theme="red3"] {
  --fabric-theme-base: #99000a;
  --fabric-theme-light: #b6151e;
  --fabric-theme-lighter: #d22931;
  --fabric-theme-lightest: #ff615b;
  --fabric-theme-lightest10: rgba(255,97,91,0.1);
  --fabric-theme-lightest35: rgba(255,97,91,0.35);
  --fabric-theme-lightest50: rgba(255,97,91,0.5);
}
[ba-theme="red4"] {
  --fabric-theme-base: #7f0000;
  --fabric-theme-light: #9b0e12;
  --fabric-theme-lighter: #b71b23;
  --fabric-theme-lightest: #f0554c;
  --fabric-theme-lightest10: rgba(240,85,76,0.1);
  --fabric-theme-lightest35: rgba(240,85,76,0.35);
  --fabric-theme-lightest50: rgba(240,85,76,0.5);
}
[ba-theme="orange1"] {
  --fabric-theme-base: #ba4e1c;
  --fabric-theme-light: #db6733;
  --fabric-theme-lighter: #fb7f49;
  --fabric-theme-lightest: #ffb077;
  --fabric-theme-lightest10: rgba(255,176,119,0.1);
  --fabric-theme-lightest35: rgba(255,176,119,0.35);
  --fabric-theme-lightest50: rgba(255,176,119,0.5);
}
[ba-theme="orange2"] {
  --fabric-theme-base: #bf3100;
  --fabric-theme-light: #dc4b11;
  --fabric-theme-lighter: #f96422;
  --fabric-theme-lightest: #ff9651;
  --fabric-theme-lightest10: rgba(255,150,81,0.1);
  --fabric-theme-lightest35: rgba(255,150,81,0.35);
  --fabric-theme-lightest50: rgba(255,150,81,0.5);
}
[ba-theme="orange3"] {
  --fabric-theme-base: #9c2100;
  --fabric-theme-light: #b93a0d;
  --fabric-theme-lighter: #d55319;
  --fabric-theme-lightest: #ff8347;
  --fabric-theme-lightest10: rgba(255,131,71,0.1);
  --fabric-theme-lightest35: rgba(255,131,71,0.35);
  --fabric-theme-lightest50: rgba(255,131,71,0.5);
}
[ba-theme="orange4"] {
  --fabric-theme-base: #811200;
  --fabric-theme-light: #9c2c04;
  --fabric-theme-lighter: #b74507;
  --fabric-theme-lightest: #f07438;
  --fabric-theme-lightest10: rgba(240,116,56,0.1);
  --fabric-theme-lightest35: rgba(240,116,56,0.35);
  --fabric-theme-lightest50: rgba(240,116,56,0.5);
}
[ba-theme="bronze1"] {
  --fabric-theme-base: #a25a00;
  --fabric-theme-light: #ca7804;
  --fabric-theme-lighter: #f19508;
  --fabric-theme-lightest: #ffc649;
  --fabric-theme-lightest10: rgba(255,198,73,0.1);
  --fabric-theme-lightest35: rgba(255,198,73,0.35);
  --fabric-theme-lightest50: rgba(255,198,73,0.5);
}
[ba-theme="bronze2"] {
  --fabric-theme-base: #ae4f00;
  --fabric-theme-light: #cb6600;
  --fabric-theme-lighter: #e77d00;
  --fabric-theme-lightest: #ffad41;
  --fabric-theme-lightest10: rgba(255,173,65,0.1);
  --fabric-theme-lightest35: rgba(255,173,65,0.35);
  --fabric-theme-lightest50: rgba(255,173,65,0.5);
}
[ba-theme="bronze3"] {
  --fabric-theme-base: #8e3f00;
  --fabric-theme-light: #a50;
  --fabric-theme-lighter: #c56b00;
  --fabric-theme-lightest: #fd9a3c;
  --fabric-theme-lightest10: rgba(253,154,60,0.1);
  --fabric-theme-lightest35: rgba(253,154,60,0.35);
  --fabric-theme-lightest50: rgba(253,154,60,0.5);
}
[ba-theme="bronze4"] {
  --fabric-theme-base: #5a330c;
  --fabric-theme-light: #724820;
  --fabric-theme-lighter: #8a5d34;
  --fabric-theme-lightest: #bc8a5f;
  --fabric-theme-lightest10: rgba(188,138,95,0.1);
  --fabric-theme-lightest35: rgba(188,138,95,0.35);
  --fabric-theme-lightest50: rgba(188,138,95,0.5);
}
[ba-theme="brown1"] {
  --fabric-theme-base: #8f6900;
  --fabric-theme-light: #ba8b00;
  --fabric-theme-lighter: #e4ac00;
  --fabric-theme-lightest: #ffdd4a;
  --fabric-theme-lightest10: rgba(255,221,74,0.1);
  --fabric-theme-lightest35: rgba(255,221,74,0.35);
  --fabric-theme-lightest50: rgba(255,221,74,0.5);
}
[ba-theme="brown2"] {
  --fabric-theme-base: #7f5b00;
  --fabric-theme-light: #a87c04;
  --fabric-theme-lighter: #d09d08;
  --fabric-theme-lightest: #ffce49;
  --fabric-theme-lightest10: rgba(255,206,73,0.1);
  --fabric-theme-lightest35: rgba(255,206,73,0.35);
  --fabric-theme-lightest50: rgba(255,206,73,0.5);
}
[ba-theme="brown3"] {
  --fabric-theme-base: #655116;
  --fabric-theme-light: #7d672c;
  --fabric-theme-lighter: #957d41;
  --fabric-theme-lightest: #c7ac6d;
  --fabric-theme-lightest10: rgba(199,172,109,0.1);
  --fabric-theme-lightest35: rgba(199,172,109,0.35);
  --fabric-theme-lightest50: rgba(199,172,109,0.5);
}
[ba-theme="brown4"] {
  --fabric-theme-base: #4e3f11;
  --fabric-theme-light: #655426;
  --fabric-theme-lighter: #7c693a;
  --fabric-theme-lightest: #ad9765;
  --fabric-theme-lightest10: rgba(173,151,101,0.1);
  --fabric-theme-lightest35: rgba(173,151,101,0.35);
  --fabric-theme-lightest50: rgba(173,151,101,0.5);
}
[ba-theme="gray1"] {
  --fabric-theme-base: #585858;
  --fabric-theme-light: #6f6f6f;
  --fabric-theme-lighter: #858585;
  --fabric-theme-lightest: #b5b5b5;
  --fabric-theme-lightest10: rgba(181,181,181,0.1);
  --fabric-theme-lightest35: rgba(181,181,181,0.35);
  --fabric-theme-lightest50: rgba(181,181,181,0.5);
}
[ba-theme="gray2"] {
  --fabric-theme-base: #383838;
  --fabric-theme-light: #4d4d4d;
  --fabric-theme-lighter: #626262;
  --fabric-theme-lightest: #8f8f8f;
  --fabric-theme-lightest10: rgba(143,143,143,0.1);
  --fabric-theme-lightest35: rgba(143,143,143,0.35);
  --fabric-theme-lightest50: rgba(143,143,143,0.5);
}
[ba-theme="cyan1"] {
  --fabric-theme-base: #045f73;
  --fabric-theme-light: #067e99;
  --fabric-theme-lighter: #067e99;
  --fabric-theme-lightest: #e6f5f9;
  --fabric-theme-lightest10: rgba(230,245,249,0.1);
  --fabric-theme-lightest35: rgba(230,245,249,0.35);
  --fabric-theme-lightest50: rgba(230,245,249,0.5);
}
[ba-theme="cyan2"] {
  --fabric-theme-base: #033f4c;
  --fabric-theme-light: #045f73;
  --fabric-theme-lighter: #045f73;
  --fabric-theme-lightest: #e6f5f9;
  --fabric-theme-lightest10: rgba(230,245,249,0.1);
  --fabric-theme-lightest35: rgba(230,245,249,0.35);
  --fabric-theme-lightest50: rgba(230,245,249,0.5);
}
[ba-theme="charcoal1"] {
  --fabric-theme-base: #474443;
  --fabric-theme-light: #5f5b5a;
  --fabric-theme-lighter: #5f5b5a;
  --fabric-theme-lightest: #f1f1f1;
  --fabric-theme-lightest10: rgba(241,241,241,0.1);
  --fabric-theme-lightest35: rgba(241,241,241,0.35);
  --fabric-theme-lightest50: rgba(241,241,241,0.5);
}
[ba-theme="charcoal2"] {
  --fabric-theme-base: #302e2d;
  --fabric-theme-light: #474443;
  --fabric-theme-lighter: #474443;
  --fabric-theme-lightest: #f1f1f1;
  --fabric-theme-lightest10: rgba(241,241,241,0.1);
  --fabric-theme-lightest35: rgba(241,241,241,0.35);
  --fabric-theme-lightest50: rgba(241,241,241,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="lime1"],
body[data-fabric-theme~="encore"] [ba-theme="lime1"] {
  --fabric-theme-base: #2e7918;
  --fabric-theme-dark: #215c10;
  --fabric-theme-light: #87c276;
  --fabric-theme-lighter: #87c276;
  --fabric-theme-lightest: #ebf5e8;
  --fabric-theme-lightest10: rgba(235,245,232,0.1);
  --fabric-theme-lightest35: rgba(235,245,232,0.35);
  --fabric-theme-lightest50: rgba(235,245,232,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="lime2"],
body[data-fabric-theme~="encore"] [ba-theme="lime2"] {
  --fabric-theme-base: #215c10;
  --fabric-theme-dark: #163d0a;
  --fabric-theme-light: #5fad48;
  --fabric-theme-lighter: #5fad48;
  --fabric-theme-lightest: #ebf5e8;
  --fabric-theme-lightest10: rgba(235,245,232,0.1);
  --fabric-theme-lightest35: rgba(235,245,232,0.35);
  --fabric-theme-lightest50: rgba(235,245,232,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="green1"],
body[data-fabric-theme~="encore"] [ba-theme="green1"] {
  --fabric-theme-base: #217950;
  --fabric-theme-dark: #195b3c;
  --fabric-theme-light: #7fc1a2;
  --fabric-theme-lighter: #7fc1a2;
  --fabric-theme-lightest: #eaf5ef;
  --fabric-theme-lightest10: rgba(234,245,239,0.1);
  --fabric-theme-lightest35: rgba(234,245,239,0.35);
  --fabric-theme-lightest50: rgba(234,245,239,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="green2"],
body[data-fabric-theme~="encore"] [ba-theme="green2"] {
  --fabric-theme-base: #195b3c;
  --fabric-theme-dark: #103c28;
  --fabric-theme-light: #54ac83;
  --fabric-theme-lighter: #54ac83;
  --fabric-theme-lightest: #eaf5ef;
  --fabric-theme-lightest10: rgba(234,245,239,0.1);
  --fabric-theme-lightest35: rgba(234,245,239,0.35);
  --fabric-theme-lightest50: rgba(234,245,239,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="teal1"],
body[data-fabric-theme~="encore"] [ba-theme="teal1"] {
  --fabric-theme-base: #24827a;
  --fabric-theme-dark: #1b625c;
  --fabric-theme-light: #81c8c2;
  --fabric-theme-lighter: #81c8c2;
  --fabric-theme-lightest: #eaf6f5;
  --fabric-theme-lightest10: rgba(234,246,245,0.1);
  --fabric-theme-lightest35: rgba(234,246,245,0.35);
  --fabric-theme-lightest50: rgba(234,246,245,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="teal2"],
body[data-fabric-theme~="encore"] [ba-theme="teal2"] {
  --fabric-theme-base: #1b625c;
  --fabric-theme-dark: #12413d;
  --fabric-theme-light: #57b5ad;
  --fabric-theme-lighter: #57b5ad;
  --fabric-theme-lightest: #eaf6f5;
  --fabric-theme-lightest10: rgba(234,246,245,0.1);
  --fabric-theme-lightest35: rgba(234,246,245,0.35);
  --fabric-theme-lightest50: rgba(234,246,245,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="cyan1"],
body[data-fabric-theme~="encore"] [ba-theme="cyan1"] {
  --fabric-theme-base: #067e99;
  --fabric-theme-dark: #045f73;
  --fabric-theme-light: #39b1cc;
  --fabric-theme-lighter: #39b1cc;
  --fabric-theme-lightest: #e6f5f9;
  --fabric-theme-lightest10: rgba(230,245,249,0.1);
  --fabric-theme-lightest35: rgba(230,245,249,0.35);
  --fabric-theme-lightest50: rgba(230,245,249,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="cyan2"],
body[data-fabric-theme~="encore"] [ba-theme="cyan2"] {
  --fabric-theme-base: #045f73;
  --fabric-theme-dark: #033f4c;
  --fabric-theme-light: #39b1cc;
  --fabric-theme-lighter: #39b1cc;
  --fabric-theme-lightest: #e6f5f9;
  --fabric-theme-lightest10: rgba(230,245,249,0.1);
  --fabric-theme-lightest35: rgba(230,245,249,0.35);
  --fabric-theme-lightest50: rgba(230,245,249,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="blue1"],
body[data-fabric-theme~="encore"] [ba-theme="blue1"] {
  --fabric-theme-base: #1a6eb5;
  --fabric-theme-dark: #145288;
  --fabric-theme-light: #7ab8ee;
  --fabric-theme-lighter: #7ab8ee;
  --fabric-theme-lightest: #e9f3fc;
  --fabric-theme-lightest10: rgba(233,243,252,0.1);
  --fabric-theme-lightest35: rgba(233,243,252,0.35);
  --fabric-theme-lightest50: rgba(233,243,252,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="blue2"],
body[data-fabric-theme~="encore"] [ba-theme="blue2"] {
  --fabric-theme-base: #145288;
  --fabric-theme-dark: #0d375a;
  --fabric-theme-light: #4da1e8;
  --fabric-theme-lighter: #4da1e8;
  --fabric-theme-lightest: #e9f3fc;
  --fabric-theme-lightest10: rgba(233,243,252,0.1);
  --fabric-theme-lightest35: rgba(233,243,252,0.35);
  --fabric-theme-lightest50: rgba(233,243,252,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="violet1"],
body[data-fabric-theme~="encore"] [ba-theme="violet1"] {
  --fabric-theme-base: #424b9c;
  --fabric-theme-dark: #313875;
  --fabric-theme-light: #979edb;
  --fabric-theme-lighter: #979edb;
  --fabric-theme-lightest: #eeeff9;
  --fabric-theme-lightest10: rgba(238,239,249,0.1);
  --fabric-theme-lightest35: rgba(238,239,249,0.35);
  --fabric-theme-lightest50: rgba(238,239,249,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="violet2"],
body[data-fabric-theme~="encore"] [ba-theme="violet2"] {
  --fabric-theme-base: #313875;
  --fabric-theme-dark: #21264e;
  --fabric-theme-light: #757ecf;
  --fabric-theme-lighter: #757ecf;
  --fabric-theme-lightest: #eeeff9;
  --fabric-theme-lightest10: rgba(238,239,249,0.1);
  --fabric-theme-lightest35: rgba(238,239,249,0.35);
  --fabric-theme-lightest50: rgba(238,239,249,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="purple1"],
body[data-fabric-theme~="encore"] [ba-theme="purple1"] {
  --fabric-theme-base: #794293;
  --fabric-theme-dark: #5b326e;
  --fabric-theme-light: #c198d4;
  --fabric-theme-lighter: #c198d4;
  --fabric-theme-lightest: #f5eef8;
  --fabric-theme-lightest10: rgba(245,238,248,0.1);
  --fabric-theme-lightest35: rgba(245,238,248,0.35);
  --fabric-theme-lightest50: rgba(245,238,248,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="purple2"],
body[data-fabric-theme~="encore"] [ba-theme="purple2"] {
  --fabric-theme-base: #5b326e;
  --fabric-theme-dark: #3c214a;
  --fabric-theme-light: #ac75c6;
  --fabric-theme-lighter: #ac75c6;
  --fabric-theme-lightest: #f5eef8;
  --fabric-theme-lightest10: rgba(245,238,248,0.1);
  --fabric-theme-lightest35: rgba(245,238,248,0.35);
  --fabric-theme-lightest50: rgba(245,238,248,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="pink1"],
body[data-fabric-theme~="encore"] [ba-theme="pink1"] {
  --fabric-theme-base: #ac2f72;
  --fabric-theme-dark: #812355;
  --fabric-theme-light: #e789bb;
  --fabric-theme-lighter: #e789bb;
  --fabric-theme-lightest: #fbebf4;
  --fabric-theme-lightest10: rgba(251,235,244,0.1);
  --fabric-theme-lightest35: rgba(251,235,244,0.35);
  --fabric-theme-lightest50: rgba(251,235,244,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="pink2"],
body[data-fabric-theme~="encore"] [ba-theme="pink2"] {
  --fabric-theme-base: #812355;
  --fabric-theme-dark: #561839;
  --fabric-theme-light: #df62a5;
  --fabric-theme-lighter: #df62a5;
  --fabric-theme-lightest: #fbebf4;
  --fabric-theme-lightest10: rgba(251,235,244,0.1);
  --fabric-theme-lightest35: rgba(251,235,244,0.35);
  --fabric-theme-lightest50: rgba(251,235,244,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="red1"],
body[data-fabric-theme~="encore"] [ba-theme="red1"] {
  --fabric-theme-base: #af361d;
  --fabric-theme-dark: #832816;
  --fabric-theme-light: #e98e7c;
  --fabric-theme-lighter: #e98e7c;
  --fabric-theme-lightest: #fbece9;
  --fabric-theme-lightest10: rgba(251,236,233,0.1);
  --fabric-theme-lightest35: rgba(251,236,233,0.35);
  --fabric-theme-lightest50: rgba(251,236,233,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="red2"],
body[data-fabric-theme~="encore"] [ba-theme="red2"] {
  --fabric-theme-base: #832816;
  --fabric-theme-dark: #581b0e;
  --fabric-theme-light: #e26950;
  --fabric-theme-lighter: #e26950;
  --fabric-theme-lightest: #fbece9;
  --fabric-theme-lightest10: rgba(251,236,233,0.1);
  --fabric-theme-lightest35: rgba(251,236,233,0.35);
  --fabric-theme-lightest50: rgba(251,236,233,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="orange1"],
body[data-fabric-theme~="encore"] [ba-theme="orange1"] {
  --fabric-theme-base: #ba5600;
  --fabric-theme-dark: #8c4100;
  --fabric-theme-light: #f2a766;
  --fabric-theme-lighter: #f2a766;
  --fabric-theme-lightest: #fdf0e5;
  --fabric-theme-lightest10: rgba(253,240,229,0.1);
  --fabric-theme-lightest35: rgba(253,240,229,0.35);
  --fabric-theme-lightest50: rgba(253,240,229,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="orange2"],
body[data-fabric-theme~="encore"] [ba-theme="orange2"] {
  --fabric-theme-base: #8c4100;
  --fabric-theme-dark: #5d2b00;
  --fabric-theme-light: #ed8933;
  --fabric-theme-lighter: #ed8933;
  --fabric-theme-lightest: #fdf0e5;
  --fabric-theme-lightest10: rgba(253,240,229,0.1);
  --fabric-theme-lightest35: rgba(253,240,229,0.35);
  --fabric-theme-lightest50: rgba(253,240,229,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="bronze1"],
body[data-fabric-theme~="encore"] [ba-theme="bronze1"] {
  --fabric-theme-base: #9f5a00;
  --fabric-theme-dark: #740;
  --fabric-theme-light: #da6;
  --fabric-theme-lighter: #da6;
  --fabric-theme-lightest: #f9f1e5;
  --fabric-theme-lightest10: rgba(249,241,229,0.1);
  --fabric-theme-lightest35: rgba(249,241,229,0.35);
  --fabric-theme-lightest50: rgba(249,241,229,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="bronze2"],
body[data-fabric-theme~="encore"] [ba-theme="bronze2"] {
  --fabric-theme-base: #740;
  --fabric-theme-dark: #502d00;
  --fabric-theme-light: #d28d33;
  --fabric-theme-lighter: #d28d33;
  --fabric-theme-lightest: #f9f1e5;
  --fabric-theme-lightest10: rgba(249,241,229,0.1);
  --fabric-theme-lightest35: rgba(249,241,229,0.35);
  --fabric-theme-lightest50: rgba(249,241,229,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="brown1"],
body[data-fabric-theme~="encore"] [ba-theme="brown1"] {
  --fabric-theme-base: #976e00;
  --fabric-theme-dark: #715300;
  --fabric-theme-light: #d7b966;
  --fabric-theme-lighter: #d7b966;
  --fabric-theme-lightest: #f8f3e5;
  --fabric-theme-lightest10: rgba(248,243,229,0.1);
  --fabric-theme-lightest35: rgba(248,243,229,0.35);
  --fabric-theme-lightest50: rgba(248,243,229,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="brown2"],
body[data-fabric-theme~="encore"] [ba-theme="brown2"] {
  --fabric-theme-base: #715300;
  --fabric-theme-dark: #4c3700;
  --fabric-theme-light: #caa133;
  --fabric-theme-lighter: #caa133;
  --fabric-theme-lightest: #f8f3e5;
  --fabric-theme-lightest10: rgba(248,243,229,0.1);
  --fabric-theme-lightest35: rgba(248,243,229,0.35);
  --fabric-theme-lightest50: rgba(248,243,229,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="charcoal1"],
body[data-fabric-theme~="encore"] [ba-theme="charcoal1"] {
  --fabric-theme-base: #5f5b5a;
  --fabric-theme-dark: #474443;
  --fabric-theme-light: #adaaa9;
  --fabric-theme-lighter: #adaaa9;
  --fabric-theme-lightest: #f1f1f1;
  --fabric-theme-lightest10: rgba(241,241,241,0.1);
  --fabric-theme-lightest35: rgba(241,241,241,0.35);
  --fabric-theme-lightest50: rgba(241,241,241,0.5);
}
body[data-fabric-theme~="encore"][ba-theme="charcoal2"],
body[data-fabric-theme~="encore"] [ba-theme="charcoal2"] {
  --fabric-theme-base: #474443;
  --fabric-theme-dark: #302e2d;
  --fabric-theme-light: #928e8d;
  --fabric-theme-lighter: #928e8d;
  --fabric-theme-lightest: #f1f1f1;
  --fabric-theme-lightest10: rgba(241,241,241,0.1);
  --fabric-theme-lightest35: rgba(241,241,241,0.35);
  --fabric-theme-lightest50: rgba(241,241,241,0.5);
}
#ui-datepicker-div .ui-datepicker-calendar .ui-state-active {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
.ui-widget {
  font-family: inherit;
}
@font-face {
  font-family: 'Ephesis';
  src: url("https://staticfe.bamboohr.com/resources/fonts/Ephesis-Regular.woff2") format('woff2'), url("https://staticfe.bamboohr.com/resources/fonts/Ephesis-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  src: url("https://staticfe.bamboohr.com/resources/fonts/Lato-Regular.woff2") format('woff2'), url("https://staticfe.bamboohr.com/resources/fonts/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  src: url("https://staticfe.bamboohr.com/resources/fonts/Lato-Italic.woff2") format('woff2'), url("https://staticfe.bamboohr.com/resources/fonts/Lato-Italic.woff") format('woff');
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url("https://staticfe.bamboohr.com/resources/fonts/Lato-Medium.woff2") format('woff2'), url("https://staticfe.bamboohr.com/resources/fonts/Lato-Medium.woff") format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'Lato';
  src: url("https://staticfe.bamboohr.com/resources/fonts/Lato-Semibold.woff2") format('woff2'), url("https://staticfe.bamboohr.com/resources/fonts/Lato-Semibold.woff") format('woff');
  font-weight: 600;
}
@font-face {
  font-family: 'Lato';
  src: url("https://staticfe.bamboohr.com/resources/fonts/Lato-Bold.woff2") format('woff2'), url("https://staticfe.bamboohr.com/resources/fonts/Lato-Bold.woff") format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Lato';
  src: url("https://staticfe.bamboohr.com/resources/fonts/Lato-Heavy.woff2") format('woff2'), url("https://staticfe.bamboohr.com/resources/fonts/Lato-Heavy.woff") format('woff');
  font-weight: 800;
}
@font-face {
  font-family: 'Fields';
  src: url("https://staticfe.bamboohr.com/assets/fonts/Fields-Regular.woff2") format('woff2');
}
@font-face {
  font-family: 'Fields';
  src: url("https://staticfe.bamboohr.com/assets/fonts/Fields-SemiBold.woff2") format('woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'Fields';
  src: url("https://staticfe.bamboohr.com/assets/fonts/Fields-Bold.woff2") format('woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'Inter';
  src: url("https://staticfe.bamboohr.com/assets/fonts/Inter-Regular.woff2") format('woff2');
}
@font-face {
  font-family: 'Inter';
  src: url("https://staticfe.bamboohr.com/assets/fonts/Inter-Medium.woff2") format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'Inter';
  src: url("https://staticfe.bamboohr.com/assets/fonts/Inter-SemiBold.woff2") format('woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  src: url("https://staticfe.bamboohr.com/assets/fonts/Inter-Bold.woff2") format('woff2');
  font-weight: 700;
}
body {
  color: var(--gray10, #222);
  font-family: 'Lato', sans-serif;
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  text-align: left;
  text-align: unset;
}
strong {
  font-weight: var(--font-weight-semibold, 600);
}
:where(body[data-fabric-theme~="encore"]) {
  font-family: 'Inter', sans-serif;
}
:where(body[data-fabric-theme~="encore"]) h1,
:where(body[data-fabric-theme~="encore"]) h2,
:where(body[data-fabric-theme~="encore"]) h3,
:where(body[data-fabric-theme~="encore"]) h4 {
  font-family: 'Fields', serif;
}
.truncate,
.truncateText {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.HoverHint {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
}
.HoverHint__content {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: none;
  margin-left: 8px;
}
.HoverHint__icon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  margin-right: 4px;
}
.HoverHint__link {
  font-size: var(--font-size-teenie, 13px);
}
.BhrForms--view .hasNote .HoverHint:hover .HoverHint__content,
.BhrForms--view .hasNote .HoverHint .HoverHint__content:hover {
  display: none;
}
.BhrForms--view .HoverHint:hover .HoverHint__content {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
}
.fieldRow,
.fieldGroup {
  margin-bottom: 16px;
}
.fieldRow .fab-Label,
.fieldGroup .fab-Label {
  min-height: 12px;
  position: relative;
}
.fieldBox {
  display: inline-block;
}
.fieldBox + .fieldBox:not(.masked) {
  margin-left: 8px;
}
.fieldBox + .fieldBox:not(.masked).spaced {
  margin-left: 16px;
}
.fieldBox.fullWidth {
  display: block;
}
.fieldDiv {
  position: relative;
  display: inline-block;
}
.fieldDiv.fullWidth {
  display: block;
}
.fieldRow > .fab-Label ~ .fieldBox > .fab-Label:first-child {
  margin-top: -20px;
}
.fieldGroup > .fieldRow {
  margin-bottom: 16px;
}
.alignedTight {
  margin-top: -20px;
}
.alignedTight .fieldBox {
  vertical-align: bottom;
}
.fab-TextInput {
  width: 128px;
}
.fab-InfoIcon {
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  cursor: pointer;
  fill: var(--gray6, #999);
  margin-left: 8px;
}
.repeatItem {
  position: relative;
}
.repeatItem:not(:last-child) {
  border-bottom: 1px solid #d4d4d4;
}
:where(body[data-fabric-theme~="encore"]) .repeatItem:not(:last-child) {
  padding-bottom: 20px;
}
.formDelete {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
:where(body[data-fabric-theme~="encore"]) .formDelete {
  top: 20px;
}
.BhrForms:not(.BhrForms--view) .fieldBox.masked,
.BhrForms--view .fieldBox.masked_value {
  display: none;
}
.BhrForms:not(.BhrForms--view) .fieldBox.masked_value,
.BhrForms--view .fieldBox.masked {
  display: inline-block;
}
.required .placeholder::after {
  content: '*';
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-family: sans-serif;
  display: inline-block;
  font-weight: var(--font-weight-semibold, 600);
  position: static;
  margin-left: 3px;
}
.limitCount--error {
  color: var(--danger, #c20b0b);
}
:where(body[data-fabric-theme~="encore"]) .limitCount--error {
  color: var(--error);
}
.limitCount--warning {
  color: var(--attention, #bd5800);
}
:where(body[data-fabric-theme~="encore"]) .limitCount--warning {
  color: var(--warning);
}
.typeahead .shareForm {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
:where(body[data-fabric-theme~="jade"]) .typeahead .inputField {
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  -webkit-box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  background-color: var(--white, #fff);
  border-color: var(--gray4, #ccc);
  color: var(--gray10, #222);
  min-height: 64px;
  padding: 6px 6px 0 6px;
  resize: none;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
:where(body[data-fabric-theme~="jade"]) .typeahead .inputField:not(.inactive) {
  box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  border-color: var(--theme-lighter-default, #82af13);
  border-color: var(--fabric-theme-lighter, #82af13);
  min-height: 64px;
  outline: 0;
}
:where(body[data-fabric-theme~="encore"]) .typeahead .inputField {
  min-height: 76px;
}
.typeahead .input-list.shown {
  min-height: 64px;
}
:where(body[data-fabric-theme~="encore"]) .typeahead .input-list.shown {
  min-height: 76px;
}
.typeahead .input-list--small {
  width: 418px;
}
.typeahead .input-list .item {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  border-radius: 3px;
  background-color: var(--gray3, #e0e0e0);
  font-size: var(--font-size-small, 14px);
  font-weight: var(--font-weight-medium, 500);
  height: 24px;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 3px 8px;
}
:where(body[data-fabric-theme~="encore"]) .typeahead .input-list .item {
  background-color: var(--gray1, #f4f4f4);
  font-size: var(--font-size-teenie, 13px);
  border-radius: 15px;
}
.typeahead .input-list .item .remove {
  cursor: pointer;
  display: inline-block;
  fill: var(--gray7, #686868);
  margin: 0 0 -1px 13px;
}
:where(body[data-fabric-theme~="encore"]) .typeahead .input-list .item .remove {
  fill: var(--gray6, #999);
}
.typeahead .input-list .description {
  color: var(--gray7, #686868);
  margin: 0;
}
.typeahead .input-list .input {
  margin-bottom: 9px;
}
.typeahead .input-list input.free {
  border: none;
  font-size: var(--font-size-small, 14px);
  height: 20px;
}
.ac_results {
  padding: 0;
  border: none;
  overflow: visible;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  width: 276px !important;
}
:where(body[data-fabric-theme~="encore"]) .ac_results {
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--gray2, #ebebeb);
  overflow: hidden auto;
}
.ac_results ul {
  outline: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:where(body[data-fabric-theme~="jade"]) .ac_results ul {
  background-color: var(--white, #fff);
  border-style: solid;
  border-width: 1px;
  -webkit-box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  border-radius: 2px;
  border-color: var(--theme-lighter-default, #82af13);
  border-color: var(--fabric-theme-lighter, #82af13);
}
:where(body[data-fabric-theme~="encore"]) .ac_results ul {
  padding: 12px 0;
}
.ac_results ul:empty {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ac_results li {
  padding: 10px 15px;
}
:where(body[data-fabric-theme~="encore"]) .ac_results li {
  color: var(--gray9, #444);
}
:where(body[data-fabric-theme~="encore"]) .ac_results li .jt {
  color: var(--gray6, #999);
}
:where(body[data-fabric-theme~="encore"]) .ac_results li strong {
  font-weight: 400;
}
:where(body[data-fabric-theme~="encore"]) .ac_results .qsPhotoGroup {
  position: relative;
  border-radius: 8px;
}
:where(body[data-fabric-theme~="encore"]) .ac_results .qsPhotoGroup svg {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
:where(body[data-fabric-theme~="encore"]) .ac_results .user .qsPhoto.qsPhotoGroup,
:where(body[data-fabric-theme~="encore"]) .ac_results .user_group .qsPhoto.qsPhotoGroup {
  background-color: var(--gray2, #ebebeb);
}
.ac_results .qsPhoto {
  height: 35px;
  width: 35px;
  margin: 0 10px 0 0;
}
:where(body[data-fabric-theme~="jade"]) .ac_results .ac_over {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  color: var(--white, #fff);
}
:where(body[data-fabric-theme~="jade"]) .ac_results .ac_over .jt {
  color: var(--white, #fff);
}
:where(body[data-fabric-theme~="encore"]) .ac_results .ac_over {
  background-color: var(--gray1, #f4f4f4);
  color: inherit;
}
:where(body[data-fabric-theme~="encore"]) .ac_results .ac_over .jt {
  color: var(--gray6, #999);
}
.typeahead--small .inputField {
  min-height: 29px;
}
.typeahead--small .input-list {
  width: 418px;
}
.typeahead--small .ac_results {
  width: unset !important;
}
.BhrForms--view label.fab-Label--required::after {
  content: none;
}
.BhrForms--view input[type=checkbox]:enabled ~ label::before {
  border-color: #e0e0e0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
:where(body[data-fabric-theme~="encore"]) .BhrForms--view input[type=checkbox]:enabled ~ label::before {
  border-color: var(--gray2);
}
.BhrForms--view input[type=text]:enabled,
.BhrForms--view textarea:enabled {
  border-color: #e0e0e0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
:where(body[data-fabric-theme~="encore"]) .BhrForms--view input[type=text]:enabled,
:where(body[data-fabric-theme~="encore"]) .BhrForms--view textarea:enabled {
  border-color: var(--gray2);
}
.BhrForms--view input[type=text]:enabled:hover:not(:focus),
.BhrForms--view textarea:enabled:hover:not(:focus) {
  border-color: #ccc;
}
:where(body[data-fabric-theme~="encore"]) .BhrForms--view input[type=text]:enabled:hover:not(:focus),
:where(body[data-fabric-theme~="encore"]) .BhrForms--view textarea:enabled:hover:not(:focus) {
  border-color: var(--gray3);
}
.BhrForms--view input[type=text] + button,
.BhrForms--view textarea + button,
.BhrForms--view input[type=text] + .ControlledCurrency__Menu--wrapper,
.BhrForms--view textarea + .ControlledCurrency__Menu--wrapper {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.BhrForms--view textarea {
  resize: none;
}
.BhrForms--view .fab-SelectToggle__innerFacade {
  border-color: #e0e0e0;
}
:where(body[data-fabric-theme~="encore"]) .BhrForms--view .fab-SelectToggle__innerFacade {
  border-color: var(--gray2);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.BhrForms--view .fab-SelectToggle--clearable .fab-SelectToggle__clearButton,
.BhrForms--view .fab-SelectToggle__toggleButton {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.BhrForms--view .fab-Select:hover:not(:focus) .fab-SelectToggle__innerFacade {
  border-color: #ccc;
}
:where(body[data-fabric-theme~="encore"]) .BhrForms--view .fab-Select:hover:not(:focus) .fab-SelectToggle__innerFacade {
  border-color: var(--gray3);
}
:where(body[data-fabric-theme~="encore"]) .BhrForms--view .fabInternal-DatePickerInput .MuiInputBase-adornedEnd {
  border-color: var(--gray2);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.BhrForms--view:not(.override-view-placeholder) label.placeholder {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: none;
}
input[type="search"]::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.fab-FormSection .BhrTable__scrollWrapper:not(:last-child) {
  margin-bottom: 16px;
}
.fab-FormSection .BhrTable__scrollWrapper:last-child:before {
  background-color: #fff;
  bottom: -1px;
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
}
.Quickform .fieldBox {
  display: inline-block;
  vertical-align: bottom;
}
.Quickform .fieldBox + .fieldBox:not(.masked) {
  margin-left: 4px;
}
.Quickform .fieldBox + .fieldBox:not(.masked).spaced {
  margin-left: 12px;
}
.Quickform .fieldRow--compensation-rate {
  color: initial;
  margin-bottom: 16px;
  display: block;
}
.Quickform .percentChange {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #555;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin: auto 17px;
}
.Quickform .percentChange-percent {
  margin-right: 5px;
}
.Quickform .percentChange-icon {
  font-size: unset;
  margin-left: 4px;
}
.Quickform .percentChange-icon-increase {
  fill: #0e840d;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.Quickform .percentChange-icon-decrease {
  fill: #c20b0b;
}
.Quickform .percentChange.nextLine {
  margin: 8px 0 3px;
  width: 100%;
}
.Quickform .CurrencyPicker {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
}
.Quickform .fab-InputWrapper--wrap {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.Quickform .currencyChange-message {
  height: 70px;
  padding: 10px 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-column-gap: 13px;
  -moz-column-gap: 13px;
  column-gap: 13px;
  background-color: #e6f5fe;
  border-radius: 4px;
  width: 435px;
  margin-top: 15px;
}
.Quickform .currencyChange-message--icon {
  fill: #0772b3;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.Quickform .currencyChange-message--text {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  line-height: 18px;
}
.Quickform .currencyChange-message--title {
  font-weight: 600;
  font-size: 15px;
  color: #222;
}
.Quickform .currencyChange-message--description {
  font-weight: 400;
  font-size: 14px;
  color: #444;
}
.employee_education {
  clear: both;
  position: relative;
}
.employee_education .educationAdd {
  margin-top: 10px;
}
.employee_education > .repeatItem:not(:last-child) {
  border-bottom-style: solid;
  width: 100%;
}
.employee_education > .repeatItem:not(.repeatPrototype) + .repeatItem {
  padding-top: 10px;
}
.employee_dependents {
  clear: both;
  position: relative;
}
.employee_dependents .repeatItem:not(.repeatPrototype) {
  padding-top: 16px;
}
.employee_dependents .repeatItem:not(.repeatPrototype):not(:nth-child(2)) {
  padding-top: 39px;
}
.employee_dependents .repeatItem:not(:last-child) {
  border-bottom-style: solid;
  width: 100%;
}
.Quickform .fab-FormNote {
  margin-left: 8px;
}
.Quickform .fab-FormNote.ssn-field--error {
  display: inline-block;
  max-width: 350px;
  width: auto;
}
.Quickform .fab-Table__row:not(.template) ~ .fab-Table__row.fab-Table__row--empty {
  display: none;
}
.Quickform .fab-Table__row--empty .fab-Table__cell {
  color: var(--gray6, #999);
}
.Quickform__tableActions {
  float: right;
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}
.Quickform__tableSubRow {
  color: var(--gray7, #686868);
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  margin-top: 2px;
}
.Quickform .fab-Table__cell {
  position: relative;
}
.Quickform .fab-Table__cellWithButton {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.Quickform .fab-Table__cellWithButton .showHideButton {
  margin-left: 4px;
}
.Quickform .fab-Table__cell--most-recent:first-of-type:before {
  content: "";
  background-color: var(--theme-lighter-default, #82af13);
  background-color: var(--fabric-theme-lighter, #82af13);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  left: 0;
  top: calc(17px + 0.5em - 1px);
}
body[data-feature-toggles~="jade"] .Quickform .fab-Table__cell--most-recent:first-of-type:before {
  top: calc(50% - 4px);
}
.Quickform .BhrTable__actions {
  white-space: nowrap;
}
.Quickform .BhrTable__header--section {
  float: left;
}
.Quickform .BhrTable__header--section + .fieldRow {
  clear: both;
}
.Quickform .BhrTable__cell {
  vertical-align: middle;
}
.Quickform .BhrTable__cell > p {
  padding: 0;
  margin: 0;
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
}
.Quickform .fab-Table__cell.fab-Table__cell--group .BhrTableSection__artboard {
  display: inline-block;
  margin-right: 2px;
}
.ba-Select__option--active,
.ba-inlineSelect__option--active,
.ba-Dropdown__option--active,
.ba-MenuDropdown__option--active,
.ba-NestedDropdown__option--active,
.ba-NestedSelect__option--active,
.ba-LargeSelect__option--active,
.ba-TextSelect__option--active,
.ba-SmallSelect__option--active,
.ba-TitleSelect__option--active {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
.BambooRichText {
  line-height: 1.4;
}
:where(body[data-fabric-theme~="encore"]) .BambooRichText {
  text-align: left;
}
.BambooRichText--error .BambooRichText__wrapper {
  border-color: var(--danger, #c20b0b);
}
.BambooRichText--error .BambooRichText__wrapper--focused {
  border: 1px solid var(--theme-lighter-default, #82af13);
  border: 1px solid var(--fabric-theme-lighter, #82af13);
  border: 1px solid var(--fabric-theme-lighter, #82af13);
  border: 1px solid var(--fabric-theme-lighter, #82af13);
  -webkit-box-shadow: 0 0 0 2px var(--theme-lighter-default, #82af13);
  box-shadow: 0 0 0 2px var(--theme-lighter-default, #82af13);
  box-shadow: 0 0 0 2px var(--fabric-theme-lighter, #82af13);
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lighter, #82af13);
  box-shadow: 0 0 0 2px var(--fabric-theme-lighter, #82af13);
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
}
.BambooRichText__wrapper {
  position: relative;
  border: 1px solid #fff;
  border-radius: 2px;
}
:where(body[data-fabric-theme~="encore"]) .BambooRichText__wrapper {
  border-radius: var(--radius-200);
}
.BambooRichText__wrapper--borderTop {
  border-top-color: #e0e0e0;
}
.BambooRichText__wrapper--border {
  border-top-color: #e0e0e0;
  border-bottom-color: #e0e0e0;
}
.BambooRichText__wrapper--borderAll {
  border-color: #e0e0e0;
}
.BambooRichText__wrapper--focused {
  border: 1px solid var(--theme-lighter-default, #82af13);
  border: 1px solid var(--fabric-theme-lighter, #82af13);
  border: 1px solid var(--fabric-theme-lighter, #82af13);
  border: 1px solid var(--fabric-theme-lighter, #82af13);
  -webkit-box-shadow: 0 0 0 2px var(--theme-lighter-default, #82af13);
  box-shadow: 0 0 0 2px var(--theme-lighter-default, #82af13);
  box-shadow: 0 0 0 2px var(--fabric-theme-lighter, #82af13);
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lighter, #82af13);
  box-shadow: 0 0 0 2px var(--fabric-theme-lighter, #82af13);
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  outline: 0;
  z-index: 1;
}
.BambooRichText:not(.BambooRichText--error) .BambooRichText__wrapper:hover:not(.BambooRichText__wrapper--focused) {
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
}
.BambooRichText__editor {
  position: relative;
  padding: 0 20px 20px;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--gray10, #222);
  font-size: var(--font-size-medium, 15px);
  font-family: "Lato", sans-serif;
  height: auto;
  min-height: 250px;
  z-index: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  padding-top: 1em;
}
:where(body[data-fabric-theme~="encore"]) .BambooRichText__editor {
  font-family: "Inter", sans-serif;
  border-radius: var(--radius-200);
  padding: 9px 16px 0px 16px;
  font-weight: 400;
}
.BambooRichText__editor--compact {
  min-height: 55px;
  padding: 16px 20px;
  font-weight: 600;
}
:where(body[data-fabric-theme~="encore"]) .BambooRichText__editor--compact {
  min-height: 40px;
  padding: 9px 16px;
  font-weight: 400;
}
.BambooRichText__editor--files {
  padding: 0 20px 20px;
}
.BambooRichText__editor--borderBottom {
  border-bottom-color: #e0e0e0;
}
.BambooRichText__editor--placeholder[contentEditable=true]:empty:before {
  line-height: 0;
  top: 27px;
}
.BambooRichText__editor--placeholder:before {
  content: attr(placeholder);
  color: var(--gray6, #999);
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  position: absolute;
}
.BambooRichText__toolbar {
  padding-bottom: 12px;
}
:where(body[data-fabric-theme~="encore"]) .BambooRichText {
  line-height: 1.5;
}
.BambooRichText p {
  margin: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}
.BambooRichText p:not([hidden]) {
  display: block;
}
.BambooRichText strong {
  font-weight: 700;
}
.BambooRichText ul,
.BambooRichText ol {
  display: block;
  list-style-position: outside !important;
  margin-left: 30px !important;
}
.BambooRichText ul {
  list-style-type: disc;
}
.BambooRichText ul ul {
  list-style-type: circle;
}
.BambooRichText ul ul ul {
  list-style-type: square;
}
.BambooRichText ol {
  list-style-type: decimal;
}
.BambooRichText ol ol {
  list-style-type: lower-alpha;
}
.BambooRichText ol ol ol {
  list-style-type: lower-roman;
}
.BambooRichText li ul,
.BambooRichText li ol {
  margin-top: 5px !important;
}
.BambooRichText li {
  display: list-item;
  text-align: -webkit-match-parent;
  margin-bottom: 5px;
  line-height: 1.4 !important;
}
:where(body[data-fabric-theme~="encore"]) .BambooRichText li {
  line-height: 1.5 !important;
}
.BambooRichText img.placeholder {
  height: 18px;
  margin: 0 1px 1px;
  vertical-align: bottom;
}
:where(body[data-fabric-theme~="encore"]) .BambooRichText img.placeholder {
  height: 22px;
  margin: 0 3px 1px;
  vertical-align: middle;
}
:where(body[data-fabric-theme~="encore"]) .BambooRichText img.placeholder[data-mce-selected] {
  outline: none;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0pt 1px #222;
  box-shadow: 0 0 0pt 1px #222;
}
.BambooRichText img.placeholder::-moz-selection {
  background-color: transparent;
}
.BambooRichText img.placeholder::selection {
  background-color: transparent;
  background: transparent;
}
.mce-tinymce-inline.mce-floatpanel {
  border: none !important;
}
.SiteContent,
#content {
  width: 100%;
  min-width: 1124px;
  max-width: 1348px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1412px) {
  .SiteContent,
  #content {
    padding: 0 32px;
  }
}
#content {
  margin-top: 32px;
  margin-bottom: 0;
}
:where(body[data-fabric-theme~="encore"]) #content {
  margin-top: 0;
  padding: 0;
}
.page {
  width: 100%;
  max-width: 100%;
  margin: 0;
}
#headerSite .page,
#navMain .page,
footer .page {
  width: 100%;
  padding: 0 32px;
  max-width: 1348px;
}
#manage-nav {
  right: 32px;
}
#js-setup-summary-banner {
  min-width: 1124px;
}
.bhrToggle,
.bhrToggleSmall {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  position: relative;
/**
	 * Fabric text type toggle
	 * e.g. Draw / Type toggle for signatures
	 */
}
.bhrToggle *,
.bhrToggleSmall *,
.bhrToggle *:before,
.bhrToggleSmall *:before,
.bhrToggle *:after,
.bhrToggleSmall *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.bhrToggle input,
.bhrToggleSmall input {
  height: 1px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  width: 1px;
}
.bhrToggle input:checked + .bhrToggle__label,
.bhrToggleSmall input:checked + .bhrToggle__label {
  background-color: rgba(14,132,13,0.2);
}
.bhrToggle input:checked + .bhrToggle__label:before,
.bhrToggleSmall input:checked + .bhrToggle__label:before {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.bhrToggle input:checked + .bhrToggle__label:after,
.bhrToggleSmall input:checked + .bhrToggle__label:after {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.bhrToggle input:checked ~ .bhrToggle__handle,
.bhrToggleSmall input:checked ~ .bhrToggle__handle {
  left: 22px;
}
.bhrToggle__label,
.bhrToggleSmall__label {
  background-color: #ebebeb;
  border: 1px solid #adadad;
  border-radius: 2px;
  display: block;
  font-size: 10px;
  height: 16px;
  position: relative;
  -webkit-transition: background-color 0.2s ease, color 0.2s ease;
  -moz-transition: background-color 0.2s ease, color 0.2s ease;
  -o-transition: background-color 0.2s ease, color 0.2s ease;
  -ms-transition: background-color 0.2s ease, color 0.2s ease;
  transition: background-color 0.2s ease, color 0.2s ease;
  width: 32px;
  line-height: var(--line-height-medium, 22px);
}
.bhrToggle__label:before,
.bhrToggleSmall__label:before {
  color: #0e840d;
  content: attr(data-on);
  left: 3px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  top: -4px;
}
.bhrToggle__label:after,
.bhrToggleSmall__label:after {
  color: #999;
  content: attr(data-off);
  position: absolute;
  right: 3px;
  top: -4px;
}
.bhrToggle__handle,
.bhrToggleSmall__handle {
  background-color: #fff;
  border: 1px solid #adadad;
  border-radius: 2px;
  display: block;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: left 0.2s ease;
  -moz-transition: left 0.2s ease;
  -o-transition: left 0.2s ease;
  -ms-transition: left 0.2s ease;
  transition: left 0.2s ease;
  width: 10px;
}
.bhrToggle--require .bhrToggle__label,
.bhrToggleSmall--require .bhrToggle__label {
  background-color: #adadad;
  border: none;
  height: 16px;
  width: 32px;
}
.bhrToggle--require .bhrToggle__label:before,
.bhrToggleSmall--require .bhrToggle__label:before {
  background-color: #fff;
  border-radius: 2px;
  content: '';
  height: 14px;
  left: 1px;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  position: absolute;
  top: 1px;
  -webkit-transition: left 0.2s ease;
  -moz-transition: left 0.2s ease;
  -o-transition: left 0.2s ease;
  -ms-transition: left 0.2s ease;
  transition: left 0.2s ease;
  width: 13px;
}
.bhrToggle--require .bhrToggle__label:after,
.bhrToggleSmall--require .bhrToggle__label:after {
  color: #fff;
  content: '*';
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
  left: 18px;
  position: absolute;
  top: 2px;
  -webkit-transition: left 0.2s ease;
  -moz-transition: left 0.2s ease;
  -o-transition: left 0.2s ease;
  -ms-transition: left 0.2s ease;
  transition: left 0.2s ease;
}
.bhrToggle--require input:checked + .bhrToggle__label,
.bhrToggleSmall--require input:checked + .bhrToggle__label {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  -webkit-box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
}
.bhrToggle--require input:checked + .bhrToggle__label:before,
.bhrToggleSmall--require input:checked + .bhrToggle__label:before {
  left: 18px;
}
.bhrToggle--require input:checked + .bhrToggle__label:after,
.bhrToggleSmall--require input:checked + .bhrToggle__label:after {
  left: 4px;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.bhrToggle--require .bhrToggle__handle,
.bhrToggleSmall--require .bhrToggle__handle {
  display: none;
}
.bhrToggle--btn input:checked + .bhrToggle__label,
.bhrToggleSmall--btn input:checked + .bhrToggle__label {
  background: none;
  color: var(--white, #fff);
  font-weight: var(--font-weight-bold, 700);
}
.bhrToggle--btn input:checked ~ .bhrToggle__handle,
.bhrToggleSmall--btn input:checked ~ .bhrToggle__handle {
  left: 0;
}
.bhrToggle--btn input:first-of-type:checked ~ .bhrToggle__handle:before,
.bhrToggleSmall--btn input:first-of-type:checked ~ .bhrToggle__handle:before {
  left: 3px;
}
.bhrToggle--btn input:last-of-type:checked ~ .bhrToggle__handle:before,
.bhrToggleSmall--btn input:last-of-type:checked ~ .bhrToggle__handle:before {
  left: 50%;
}
.bhrToggle--btn input:focus ~ .bhrToggle__handle,
.bhrToggleSmall--btn input:focus ~ .bhrToggle__handle {
  border-color: var(--theme-lighter-default, #82af13);
  border-color: var(--fabric-theme-lighter, #82af13);
  -webkit-box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
}
.bhrToggle--btn .bhrToggle__label,
.bhrToggleSmall--btn .bhrToggle__label {
  background: none;
  border: none;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  color: var(--gray7, #686868);
  font-weight: var(--font-weight-medium, 500);
  font-size: var(--font-size-large, 16px);
  vertical-align: middle;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-width: 64px;
  padding: 8px;
  text-align: center;
  text-transform: capitalize;
  width: auto;
  z-index: 1;
}
.bhrToggle--btn .bhrToggle__label:before,
.bhrToggleSmall--btn .bhrToggle__label:before,
.bhrToggle--btn .bhrToggle__label:after,
.bhrToggleSmall--btn .bhrToggle__label:after {
  content: none;
}
.bhrToggle--btn .bhrToggle__label:first-of-type,
.bhrToggleSmall--btn .bhrToggle__label:first-of-type {
  left: 3px;
}
.bhrToggle--btn .bhrToggle__label:last-of-type,
.bhrToggleSmall--btn .bhrToggle__label:last-of-type {
  right: 2px;
}
.bhrToggle--btn .bhrToggle__handle,
.bhrToggleSmall--btn .bhrToggle__handle {
  border: 1px solid #ccc;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  height: calc(100% - 2px);
  left: 0;
  padding: 3px;
  top: 1px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bhrToggle--btn .bhrToggle__handle:before,
.bhrToggleSmall--btn .bhrToggle__handle:before {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  border: none;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  content: '';
  height: calc(100% - 6px);
  position: absolute;
  top: 3px;
  -webkit-transition: left 0.2s ease;
  -moz-transition: left 0.2s ease;
  -o-transition: left 0.2s ease;
  -ms-transition: left 0.2s ease;
  transition: left 0.2s ease;
  width: calc(50% - 3px);
}
.BhrForms--view .bhrToggle--btn .bhrToggle__handle,
.BhrForms--view .bhrToggleSmall--btn .bhrToggle__handle {
  border-color: #e0e0e0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.BhrForms--view .bhrToggle--btn .bhrToggle__handle:before,
.BhrForms--view .bhrToggleSmall--btn .bhrToggle__handle:before {
  background-color: #999;
}
.bhrToggle--btn--disabled,
.bhrToggleSmall--btn--disabled {
  cursor: default;
  pointer-events: none;
}
.bhrToggle--btn--disabled .bhrToggle__handle,
.bhrToggleSmall--btn--disabled .bhrToggle__handle {
  border-color: #e0e0e0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bhrToggle--btn--disabled .bhrToggle__handle:before,
.bhrToggleSmall--btn--disabled .bhrToggle__handle:before {
  background-color: #ccc;
}
.bhrToggleSmall {
  display: inline-block;
}
.bhrToggleSmall .bhrToggle__label {
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  min-width: auto;
  padding: 7px;
  width: 28px;
}
.bhrToggleSmall .bhrToggle__handle:before {
  width: 26px;
}
input.roundToggle {
  height: 1px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  width: 1px;
}
input.roundToggle + label {
  background-color: #adadad;
  border-radius: 9px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  position: relative;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 32px;
}
input.roundToggle + label:before {
  content: '';
  background-color: #fff;
  border-radius: 8px;
  -webkit-box-shadow: 1px 0 0 0 rgba(0,0,0,0.2);
  box-shadow: 1px 0 0 0 rgba(0,0,0,0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  height: 16px;
  left: 1px;
  position: absolute;
  top: 1px;
  -webkit-transition: left 0.2s ease;
  -moz-transition: left 0.2s ease;
  -o-transition: left 0.2s ease;
  -ms-transition: left 0.2s ease;
  transition: left 0.2s ease;
  width: 16px;
}
input.roundToggle:checked + label {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  -webkit-box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
}
input.roundToggle:checked + label:before {
  -webkit-box-shadow: -1px 0 0 0 rgba(0,0,0,0.2);
  box-shadow: -1px 0 0 0 rgba(0,0,0,0.2);
  left: 15px;
}
.PageHeader {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  text-align: left;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  min-width: 0;
  top: 0;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader {
  padding-bottom: 32px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--backButton {
  padding-top: 0;
}
.PageHeader--noBorder {
  border-bottom: 0;
}
.PageHeader--borderPadding {
  padding-bottom: 175px !important;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--borderPadding {
  padding-bottom: unset !important;
}
.PageHeader--sm {
  min-height: 89px;
  max-height: 105px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--sm {
  min-height: unset;
  max-height: unset;
}
.PageHeader--md {
  min-height: 144px;
  max-height: 161px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--md {
  min-height: unset;
  max-height: unset;
}
.PageHeader--mdBrand,
.PageHeader--xl,
.PageHeader--lg {
  height: 196px;
  position: relative;
  color: var(--white, #fff);
  fill: var(--white, #fff);
  padding-bottom: 80px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--mdBrand,
:where(body[data-fabric-theme~="encore"]) .PageHeader--xl,
:where(body[data-fabric-theme~="encore"]) .PageHeader--lg {
  padding-bottom: 80px;
  padding-top: 0;
}
.PageHeader--mdBrand > *,
.PageHeader--xl > *,
.PageHeader--lg > * {
  z-index: 1;
}
.PageHeader--mdBrand:before,
.PageHeader--xl:before,
.PageHeader--lg:before,
.PageHeader--mdBrand:after,
.PageHeader--xl:after,
.PageHeader--lg:after {
  content: '';
  width: 100vw;
  position: absolute;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--mdBrand:before,
:where(body[data-fabric-theme~="encore"]) .PageHeader--xl:before,
:where(body[data-fabric-theme~="encore"]) .PageHeader--lg:before,
:where(body[data-fabric-theme~="encore"]) .PageHeader--mdBrand:after,
:where(body[data-fabric-theme~="encore"]) .PageHeader--xl:after,
:where(body[data-fabric-theme~="encore"]) .PageHeader--lg:after {
  width: unset;
  top: calc(var(--page-capsule-padding) * -1);
  left: calc(var(--page-capsule-padding) * -1);
  right: calc(var(--page-capsule-padding) * -1);
  margin-left: unset;
  margin-right: unset;
}
.PageHeader--mdBrand:before,
.PageHeader--xl:before,
.PageHeader--lg:before {
  height: 196px;
  background-image: -webkit-linear-gradient(0deg, #82af13 0, #527a00 40%);
  background-image: -moz-linear-gradient(0deg, #82af13 0, #527a00 40%);
  background-image: -o-linear-gradient(0deg, #82af13 0, #527a00 40%);
  background-image: -ms-linear-gradient(0deg, #82af13 0, #527a00 40%);
  background-image: linear-gradient(90deg, #82af13 0, #527a00 40%);
  background-image: -webkit-linear-gradient(0deg, var(--fabric-theme-lighter, #82af13) 0, var(--fabric-theme-base, #527a00) 40%);
  background-image: -moz-linear-gradient(0deg, var(--fabric-theme-lighter, #82af13) 0, var(--fabric-theme-base, #527a00) 40%);
  background-image: -o-linear-gradient(0deg, var(--fabric-theme-lighter, #82af13) 0, var(--fabric-theme-base, #527a00) 40%);
  background-image: -ms-linear-gradient(0deg, var(--fabric-theme-lighter, #82af13) 0, var(--fabric-theme-base, #527a00) 40%);
  background-image: linear-gradient(90deg, var(--fabric-theme-lighter, #82af13) 0, var(--fabric-theme-base, #527a00) 40%);
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--mdBrand:before,
:where(body[data-fabric-theme~="encore"]) .PageHeader--xl:before,
:where(body[data-fabric-theme~="encore"]) .PageHeader--lg:before {
  background-image: unset;
  background-color: var(--fabric-theme-base);
}
.PageHeader--mdBrand:after,
.PageHeader--xl:after,
.PageHeader--lg:after {
  bottom: 0;
  height: 3px;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  background: -webkit-linear-gradient(270deg, rgba(0,0,0,0) 0, rgba(0,0,0,0.1) 100%);
  background: -moz-linear-gradient(270deg, rgba(0,0,0,0) 0, rgba(0,0,0,0.1) 100%);
  background: -o-linear-gradient(270deg, rgba(0,0,0,0) 0, rgba(0,0,0,0.1) 100%);
  background: -ms-linear-gradient(270deg, rgba(0,0,0,0) 0, rgba(0,0,0,0.1) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0, rgba(0,0,0,0.1) 100%);
}
.PageHeader--mdBrand .PageHeader__backBtn,
.PageHeader--xl .PageHeader__backBtn,
.PageHeader--lg .PageHeader__backBtn {
  color: var(--white, #fff);
  fill: var(--white, #fff);
}
.PageHeader--mdBrand .PageHeader__title,
.PageHeader--xl .PageHeader__title,
.PageHeader--lg .PageHeader__title {
  color: var(--white, #fff);
}
.PageHeader--mdBrand .PageHeader__titleIcon,
.PageHeader--xl .PageHeader__titleIcon,
.PageHeader--lg .PageHeader__titleIcon {
  fill: var(--white, #fff);
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--rounded:before {
  border-radius: var(--radius-600);
  left: calc(var(--page-capsule-padding) * -1);
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--rounded:after {
  display: none;
}
.PageHeader--mdBrand {
  height: 200px;
}
.PageHeader--mdBrand:before {
  height: 200px;
}
.PageHeader--mdBrand .PageHeader__titleWrap {
  margin-top: 32px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--mdBrand .PageHeader__titleWrap {
  margin-top: unset;
}
.PageHeader--lg .PageHeader__title {
  line-height: 48px;
}
.PageHeader--lg .PageHeader__titleIcon {
  margin-top: 4px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--xl {
  height: var(--page-header-height-xl);
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--xl:before {
  height: calc(var(--page-header-height-xl) + var(--page-capsule-padding));
}
.PageHeader--xl .PageHeader__titleWrap {
  margin-top: 32px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader--xl .PageHeader__titleWrap {
  margin-top: unset;
}
.PageHeader__backBtn {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: auto;
  margin-bottom: 18px;
  margin-top: 12px;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray7, #686868);
  fill: var(--gray7, #686868);
}
:where(body[data-fabric-theme~="encore"]) .PageHeader__backBtn {
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  margin-top: 0;
  margin-bottom: 16px;
}
.PageHeader__backBtnArrow {
  margin-right: 6px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader__backBtnArrow {
  margin-right: 8px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader__backBtn + .PageHeader__titleWrap {
  margin-top: 0;
}
.PageHeader__left {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
}
.PageHeader__left--sameLineSubTitle {
  -webkit-box-align: baseline;
  -moz-box-align: baseline;
  -o-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.PageHeader__title {
  margin: 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader__title {
  font-size: var(--font-size-h1, 34px);
  line-height: var(--line-height-h1, 42px);
  font-weight: 700;
  overflow: unset;
  -o-text-overflow: unset;
  text-overflow: unset;
  white-space: normal;
}
.PageHeader__titleWrap {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 10px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader__titleWrap {
  margin-top: unset;
  margin-bottom: unset;
}
.PageHeader__titleIcon {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  line-height: 0;
  margin-right: 12px;
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
:where(body[data-fabric-theme~="encore"]) .PageHeader__titleIcon {
  margin-right: 8px;
}
.PageHeader__titleSecondary {
  margin-left: 8px;
}
.PageHeader__titleSubTitleWrap--sm,
.PageHeader__titleSubTitleWrap--md,
.PageHeader__titleSubTitleWrap--mdBrand {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: baseline;
  -moz-box-align: baseline;
  -o-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.PageHeader__title--secondaryInline {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: baseline;
  -moz-box-align: baseline;
  -o-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.PageHeader__subTitle {
  font-size: var(--font-size-biggie, 18px);
  line-height: var(--line-height-biggie, 26px);
  color: var(--gray7, #686868);
  padding: 0;
  margin: 0 0 0 8px;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader__subTitle {
  color: var(--gray6, #999);
  font-weight: var(--font-weight-semibold, 600);
  font-size: var(--font-size-h3, 22px);
  line-height: var(--line-height-h3, 30px);
  font-family: 'Fields', serif;
  margin-left: 24px;
}
.PageHeader__subTitle--large {
  margin: 0;
  color: var(--white, #fff);
  font-weight: var(--font-weight-medium, 500);
}
.PageHeader__optionalContentWrap {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  padding-bottom: 11px;
  padding-top: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 50px;
}
.PageHeader__optionalContentWrap:not(:has(:not(:empty))) {
  display: none;
}
:where(body[data-fabric-theme~="encore"]) .PageHeader__optionalContentWrap {
  margin-top: 20px;
  padding: unset;
  height: unset;
}
.PageHeader__optionalContent--right {
  margin-left: auto;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  -ms-flex-item-align: end;
}
.PageHeader--homeFixed {
  position: sticky;
  z-index: 200;
  border-bottom: none;
  -webkit-transition: top ease-in-out 0.2s;
  -moz-transition: top ease-in-out 0.2s;
  -o-transition: top ease-in-out 0.2s;
  -ms-transition: top ease-in-out 0.2s;
  transition: top ease-in-out 0.2s;
  height: 54px;
  padding-bottom: 0;
}
.PageHeader--homeFixed:before {
  height: 54px;
}
.PageHeader--homeFixed:after {
  bottom: 146px;
}
.PageHeader--homeFixed .PageHeader__titleWrap {
  margin: 0;
}
.PageHeader--homeFixed .PageHeader__optionalContent--right {
  position: relative;
  top: 10px;
}
.PageHeader--homeFixed .PageHeader__homeFixedheightPreserve {
  background: #00f;
  height: 300px;
}
#content .PageHeader {
  margin-top: -29px;
}
:where(body[data-fabric-theme~="encore"]) #content .PageHeader {
  margin-top: 0;
}
.content-tight-top #content .PageHeader {
  margin-top: 0;
}
:where(body[data-fabric-theme~="encore"]).HomeFeature #content .PageHeader--mdBrand,
:where(body[data-fabric-theme~="encore"]).HomeFeature #content .PageHeader--xl,
:where(body[data-fabric-theme~="encore"]).HomeFeature #content .PageHeader--lg {
  fill: unset;
  height: unset;
  margin-top: 0;
  padding-bottom: 0;
}
:where(body[data-fabric-theme~="encore"]).HomeFeature #content .PageHeader:before {
  background-color: unset;
}
:where(body[data-fabric-theme~="encore"]).HomeFeature #content .PageHeader:after {
  display: none;
}
:where(body[data-fabric-theme~="encore"]).HomeFeature #content .PageHeader__titleWrap {
  margin-bottom: 32px;
  margin-top: unset;
}
:where(body[data-fabric-theme~="encore"]).HomeFeature #content .PageHeader__optionalContent--right {
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
}
:where(body[data-fabric-theme~="encore"]).content-tight-top #content .PageHeader {
  margin-top: 0;
}
:where(body[data-fabric-theme~="encore"]).employeeSection #content .PageHeader--lg {
  height: var(--page-header-height-lg);
  padding-bottom: unset;
}
:where(body[data-fabric-theme~="encore"]).employeeSection #content .PageHeader--lg:before {
  border-radius: var(--radius-600);
  margin-left: 0;
  margin-right: 0;
  width: unset;
  left: calc(var(--page-capsule-padding) * -1);
}
:where(body[data-fabric-theme~="encore"]).employeeSection #content .PageHeader--lg:after {
  display: none;
}
:where(body[data-fabric-theme~="encore"]).employeeSection #content .PageHeader.PageHeader__looping-nav {
  margin-top: var(--page-header-looping-offset-top);
}
:where(body[data-fabric-theme~="encore"]).employeeSection #content .PageHeader.PageHeader__looping-nav:before {
  height: calc(var(--page-capsule-padding) + var(--page-header-looping-offset-top) + var(--page-header-height-lg));
  top: calc((var(--page-capsule-padding) + var(--page-header-looping-offset-top)) * -1);
}
:where(body[data-fabric-theme~="encore"]).employeeSection #content .PageHeader:before {
  height: calc(var(--page-capsule-padding) + var(--page-header-height-lg));
}
:where(body[data-fabric-theme~="encore"]).employeeSection #content .PageHeader__title {
  margin-bottom: 4px;
}
:where(body[data-fabric-theme~="encore"]).employeeSection #content .PageHeader__optionalContent--right {
  margin-left: auto;
  -webkit-align-self: flex-start;
  align-self: flex-start;
  -ms-flex-item-align: start;
}
@media screen and (max-width: 1188px) {
  .PageHeader--mdBrand:before,
  .PageHeader--lg:before,
  .PageHeader--mdBrand:after,
  .PageHeader--lg:after {
    min-width: 1124px;
    left: -32px;
    right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
.CalendarPicker__rangeRow {
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.CalendarPicker__rangeColumn {
  display: block;
}
.CalendarPicker__rangeSeparator {
  line-height: 28px;
  margin: 0 7px;
}
.CalendarPicker__popover {
  border-color: var(--theme-base-default, #527a00);
  border-color: var(--fabric-theme-base, #527a00);
  -webkit-box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
}
input.CalendarPicker__input ~ .CalendarPicker__toggleButton {
  -webkit-box-shadow: none;
  box-shadow: none;
  height: calc(100% - 2px);
  line-height: normal;
  margin: 0;
  min-width: 0;
  right: 1px;
  top: 1px;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  -ms-transform: none;
  transform: none;
  border: none;
  cursor: pointer;
}
input.CalendarPicker__input ~ .CalendarPicker__toggleButton:focus {
  right: 1px;
  padding-right: 6px;
}
input.CalendarPicker__input:disabled ~ .CalendarPicker__toggleButton {
  cursor: default;
}
.BhrForms input.CalendarPicker__input {
  margin-top: 0;
}
.CalendarPicker__rangeColumn + .CalendarPicker__rangeColumn > .fab-Label {
  padding-left: 22px;
}
.CalendarGridManager__previousButton,
.CalendarGridManager__nextButton {
  background-color: #fff;
  padding-top: 4px;
  height: 32px;
}
.CalendarGridManager__gridTitle {
  font-family: unset;
}
.CalendarGridManager .CalendarGridManager__gridHeaderDropdown > a {
  background-color: #fff;
}
.CalendarGridManager__gridHeader {
  height: initial;
  padding: 10px 12px;
}
.CalendarGridManager__gridHeaderContent .chzn-container-single:first-of-type {
  width: 75px !important;
}
.CalendarGridManager__gridHeaderContent .chzn-container-single:last-of-type {
  width: 80px !important;
}
.CalendarGridManager__gridHeaderContent .chzn-container-single b {
  width: 25px;
  background-color: var(--gray1, #f4f4f4);
}
.CalendarGridManager__gridHeaderContent .chzn-container-single:not(.small) .chzn-single b {
  background-position: 4px 3px;
}
.CalendarGridManager__gridHeaderContent .chzn-container-single:not(.small) .chzn-single span {
  margin-top: 3px;
}
.CalendarGridManager__gridHeaderContent .chzn-container-single .chzn-single {
  height: 32px;
}
.CalendarGridManager__gridHeaderContent .chzn-container-single .chzn-single div {
  right: 7px;
}
.CalendarGridManager__gridHeaderContent .chzn-container-single .chzn-drop {
  width: calc(100% - 2px) !important;
}
.CalendarGrid__daysOfWeek {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-bottom: 0;
}
.CalendarGrid__cell--withValue:not(.CalendarGrid__cell--disabled):hover {
  background-color: var(--theme-lightest10-default, rgba(192,233,79,0.1));
  background-color: var(--fabric-theme-lightest10, rgba(192,233,79,0.1));
  border-bottom-right-radius: 2px;
}
.CalendarGrid__cell--withValue:not(.CalendarGrid__cell--disabled):hover:before {
  border-color: var(--theme-base-default, #527a00);
  border-color: var(--fabric-theme-base, #527a00);
  border-radius: 2px;
}
.CalendarGrid__cell--selected {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  border-bottom-right-radius: 2px;
}
.CalendarGrid__cell--selected:before {
  border-color: var(--theme-base-default, #527a00);
  border-color: var(--fabric-theme-base, #527a00);
  border-radius: 2px;
}
.CalendarGrid__cell--now:before {
  border-color: #ccc;
  border-radius: 2px;
}
.CalendarGrid__cell--disabled {
  color: var(--gray6, #999);
}
.CalendarGrid__cell--inRange:not(.CalendarGrid__cell--selected) {
  background-color: var(--theme-lightest10-default, rgba(192,233,79,0.1));
  background-color: var(--fabric-theme-lightest10, rgba(192,233,79,0.1));
}
.CalendarGrid__cell--inRange:not(.CalendarGrid__cell--selected):before {
  border-color: var(--theme-lightest50-default, rgba(192,233,79,0.5));
  border-color: var(--fabric-theme-lightest50, rgba(192,233,79,0.5));
  border-left-color: var(--theme-lightest35-default, rgba(192,233,79,0.35));
  border-left-color: var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
}
.CalendarGrid__cell--selected.CalendarGrid__cell--now:before {
  border-color: var(--theme-base-default, #527a00);
  border-color: var(--fabric-theme-base, #527a00);
  border-radius: 2px;
}
.CalendarGrid__cell--selected.CalendarGrid__cell--now.CalendarGrid__cell--inRange {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
.SiteHeader {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: var(--gray2, #ebebeb);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 80px;
  margin: 0 0 3px 0;
  padding: 0 32px;
  position: relative;
  right: 0;
  left: 0;
  min-width: 1124px;
}
.SiteHeader::after {
  content: '';
  background-image: -webkit-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background-image: -moz-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background-image: -o-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background-image: -ms-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background-image: linear-gradient(90deg, #c0e94f 0, #82af13 100%);
  background-image: -webkit-linear-gradient(0deg, var(--fabric-theme-lightest, #c0e94f) 0, var(--fabric-theme-lighter, #82af13) 100%);
  background-image: -moz-linear-gradient(0deg, var(--fabric-theme-lightest, #c0e94f) 0, var(--fabric-theme-lighter, #82af13) 100%);
  background-image: -o-linear-gradient(0deg, var(--fabric-theme-lightest, #c0e94f) 0, var(--fabric-theme-lighter, #82af13) 100%);
  background-image: -ms-linear-gradient(0deg, var(--fabric-theme-lightest, #c0e94f) 0, var(--fabric-theme-lighter, #82af13) 100%);
  background-image: linear-gradient(90deg, var(--fabric-theme-lightest, #c0e94f) 0, var(--fabric-theme-lighter, #82af13) 100%);
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
}
.SiteHeader--dark {
  background-color: var(--gray9, #444);
}
.SiteHeader--dark .SiteBrand__logo {
  fill: var(--white, #fff);
}
.SiteHeader--dark .SiteNavigation__link {
  color: var(--white, #fff);
}
.SiteHeader--dark .SiteNavigation__link:before {
  color: var(--white, #fff);
}
.SiteHeader--dark .SiteNavigation__link:hover,
.SiteHeader--dark .SiteNavigation__link:focus {
  color: var(--white, #fff);
  background-color: var(--gray8, #555);
}
.SiteHeader--dark .SiteNavigation__link--active {
  background-color: var(--gray8, #555);
}
.SiteHeader--dark .SiteSearchWrapper:before {
  background-image: -webkit-linear-gradient(180deg, #444 55%, rgba(68,68,68,0) 100%);
  background-image: -moz-linear-gradient(180deg, #444 55%, rgba(68,68,68,0) 100%);
  background-image: -o-linear-gradient(180deg, #444 55%, rgba(68,68,68,0) 100%);
  background-image: -ms-linear-gradient(180deg, #444 55%, rgba(68,68,68,0) 100%);
  background-image: linear-gradient(270deg, #444 55%, rgba(68,68,68,0) 100%);
}
.SiteBrand {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  min-width: auto;
}
.SiteBrand__link {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.SiteBrand__logo {
  max-width: 300px;
  height: auto;
}
.SiteBrand .bambooHRHeaderLogo {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.SiteNavigation {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  min-width: auto;
}
.SiteNavigation__link {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: var(--gray7, #686868);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: var(--font-size-large, 16px);
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-medium, 22px);
  padding: 0 16px;
  -webkit-transition: padding 0.2s ease-in-out;
  -moz-transition: padding 0.2s ease-in-out;
  -o-transition: padding 0.2s ease-in-out;
  -ms-transition: padding 0.2s ease-in-out;
  transition: padding 0.2s ease-in-out;
  height: 100%;
  white-space: nowrap;
}
.SiteNavigation__link:first-of-type {
  margin-left: 36px;
}
.SiteNavigation__link:hover,
.SiteNavigation__link:focus {
  background-color: var(--gray1, #f4f4f4);
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  text-decoration: none;
}
.SiteNavigation__link--active {
  background-color: var(--gray1, #f4f4f4);
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-weight: var(--font-weight-heavy, 800);
}
.SiteManageNav {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 36px;
  position: relative;
  width: auto;
  min-width: auto;
}
.SiteManageNav__item {
  background-color: transparent;
  border: 0;
  fill: var(--gray6, #999);
  padding: 0;
  min-width: 24px;
  margin-left: 20px;
}
.SiteManageNav__item:hover {
  cursor: pointer;
}
.SiteManageNav__item:hover,
.SiteManageNav__item:active,
.SiteManageNav__item:focus,
.SiteManageNav__item--active,
.SiteManageNav__item--open {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.SiteManageNav__textLink {
  margin-left: 20px;
  overflow: hidden;
  white-space: nowrap;
}
.SiteManageNav__avatar--dark {
  border: 2px solid #fff;
}
.ManageNavItemDropdown {
  background-color: var(--white, #fff);
  border: solid 1px var(--theme-lighter-default, #82af13);
  border: solid 1px var(--fabric-theme-lighter, #82af13);
  border: solid 1px var(--fabric-theme-lighter, #82af13);
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  position: absolute;
  right: 0;
  top: calc(100% + 4px);
  z-index: 2000;
}
.ManageNavItemDropdown__itemCount {
  color: var(--gray7, #686868);
  margin-left: 6px;
}
.ManageNavItemDropdown__itemIcon {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 22px;
}
.ManageNavItemDropdown__itemIcon--center {
  margin-right: 8px;
  min-width: 14px;
  width: auto;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.ManageNavItemDropdown__itemGray_Icon {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  fill: var(--gray7, #686868);
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 22px;
}
.ManageNavItemDropdown__itemGray_Icon--center {
  margin-right: 8px;
  min-width: 14px;
  width: auto;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.ManageNavItemDropdown__itemLink {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--gray10, #222);
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-small, 20px);
  min-height: 32px;
  padding: 0 9px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ManageNavItemDropdown__itemLink--button {
  border: none;
  width: 100%;
}
.ManageNavItemDropdown__itemLink--button--white {
  background-color: var(--white, #fff);
}
.ManageNavItemDropdown__itemLink--zendesk {
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.ManageNavItemDropdown__itemLink--zendesk .ManageNavItemDropdown__itemIcon {
  margin-top: 15px;
}
.ManageNavItemDropdown__itemLink--zendesk .ManageNavItemDropdown__itemText {
  margin-top: 10px;
  max-width: 256px;
  white-space: normal;
}
.ManageNavItemDropdown__itemLink:hover {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  cursor: pointer;
  color: var(--white, #fff);
  text-decoration: none;
}
.ManageNavItemDropdown__itemLink:hover .ManageNavItemDropdown__itemCount {
  color: var(--white, #fff);
}
.ManageNavItemDropdown__itemLink:hover .ManageNavItemDropdown__itemIcon {
  fill: var(--white, #fff);
}
.ManageNavItemDropdown__itemLink:hover .ManageNavItemDropdown__itemText-detail {
  color: var(--white, #fff);
}
body:not([data-feature-toggles~="jade"]) .ManageNavItemDropdown__itemLink:hover {
  color: var(--white, #fff);
}
.ManageNavItemDropdown__itemGray_Link {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--gray7, #686868);
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-small, 20px);
  min-height: 32px;
  padding: 0 9px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ManageNavItemDropdown__itemGray_Link:hover {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  cursor: pointer;
  color: var(--white, #fff);
  text-decoration: none;
}
.ManageNavItemDropdown__itemGray_Link:hover .ManageNavItemDropdown__itemGray_Icon {
  fill: var(--white, #fff);
}
.ManageNavItemDropdown__itemText-detail {
  color: var(--gray8, #555);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  margin-bottom: 12px;
  margin-top: 2px;
}
.ManageNavItemDropdown--account {
  min-width: 310px;
}
.ManageNavItemDropdown__header {
  background-color: #f4f4f4;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 10px 9px;
}
.ManageNavItemDropdown__headerAvatar {
  border-radius: 50%;
  margin: 4px 16px 4px 0;
}
.ManageNavItemDropdown__headerEmployeeInfo {
  margin-top: 9px;
}
.ManageNavItemDropdown__headerHeading {
  font-size: var(--font-size-large, 16px);
  line-height: var(--line-height-medium, 22px);
  font-weight: var(--font-weight-semibold, 600);
  display: block;
}
.ManageNavItemDropdown__headerInfo {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-top: 1px;
  text-align: left;
}
.ManageNavItemDropdown__search {
  position: relative;
}
.ManageNavItemDropdown__searchBox {
  border: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: unset;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  height: 30px;
  padding: 0 13px 0 33px;
  width: 100%;
}
.ManageNavItemDropdown__searchBox:placeholder {
  color: var(--gray6, #999);
}
.ManageNavItemDropdown__searchIcon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  left: 13px;
  position: absolute;
  top: 9px;
}
.ManageNavItemDropdown--inbox {
  min-width: 210px;
}
.ManageNavItemDropdown--open {
  display: block;
}
.ManageNavItemDropdown--section {
  border-top: 1px solid #e0e0e0;
  padding: 4px 0;
}
.ManageNavItemDropdown--section:empty {
  border: 0;
  padding: 0;
}
.ManageNavItemDropdown--noBorderTop {
  border-top: 0;
}
.SiteSearch {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 36px;
  min-width: 160px;
  position: relative;
  margin-right: 20px;
  margin-left: 22px;
}
.SiteSearchWrapper {
  position: absolute;
  right: 0;
  top: 0;
}
.SiteSearchWrapper:before {
  content: '';
  display: block;
  background-image: -webkit-linear-gradient(180deg, #ebebeb 55%, rgba(235,235,235,0) 100%);
  background-image: -moz-linear-gradient(180deg, #ebebeb 55%, rgba(235,235,235,0) 100%);
  background-image: -o-linear-gradient(180deg, #ebebeb 55%, rgba(235,235,235,0) 100%);
  background-image: -ms-linear-gradient(180deg, #ebebeb 55%, rgba(235,235,235,0) 100%);
  background-image: linear-gradient(270deg, #ebebeb 55%, rgba(235,235,235,0) 100%);
  position: absolute;
  top: -22px;
  width: 110px;
  left: -60px;
  height: 80px;
}
.SiteSearch__clear {
  fill: var(--gray6, #999);
  position: absolute;
  top: 9px;
  right: 12px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  pointer-events: none;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.SiteSearch__clear:hover {
  cursor: pointer;
}
.SiteSearch__clear--show {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  pointer-events: auto;
}
.SiteSearch__icon {
  background-color: transparent;
  border-radius: 18px 0 0 18px;
  fill: var(--gray6, #999);
  height: 34px;
  left: 1px;
  position: absolute;
  top: 1px;
  padding: 0 6px 0 8px;
  pointer-events: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.SiteSearch__input {
  border: 1px solid transparent;
  border-radius: 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: var(--white, #fff);
  color: var(--gray10, #222);
  font-size: var(--font-size-large, 16px);
  font-family: inherit;
  height: 36px;
  padding: 0 0 0 37px;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 160px;
  -webkit-appearance: none;
}
.SiteSearch__input::-ms-clear {
  display: none;
}
.SiteSearch__input:hover,
.SiteSearch__input:focus {
  background-color: var(--white, #fff);
}
.SiteSearch__input:focus,
.SiteSearch__input--focused {
  box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  border-color: var(--theme-lighter-default, #82af13);
  border-color: var(--fabric-theme-lighter, #82af13);
  outline: 0;
  width: 228px;
}
.SiteSearch__input:focus + .SiteSearch__icon,
.SiteSearch__input--focused + .SiteSearch__icon {
  background-color: var(--gray1, #f4f4f4);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.SiteSearch__input--blurred {
  color: var(--gray6, #999);
}
.SiteSearch__input::placeholder {
  color: var(--gray6, #999);
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  -webkit-transition-delay: 0.05s;
  -moz-transition-delay: 0.05s;
  -o-transition-delay: 0.05s;
  -ms-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.SiteSearch__input:-ms-input-placeholder {
  color: var(--gray6, #999);
  font-family: inherit;
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  -webkit-transition-delay: 0.05s;
  -moz-transition-delay: 0.05s;
  -o-transition-delay: 0.05s;
  -ms-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.SiteSearch__input::-webkit-input-placeholder {
  color: var(--gray6, #999);
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  -webkit-transition-delay: 0.05s;
  -moz-transition-delay: 0.05s;
  -o-transition-delay: 0.05s;
  -ms-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.SiteSearch__results {
  top: 42px;
  max-height: calc(100vh - 85px);
  overflow-y: auto;
}
@media only screen and (min-width: 1800px) {
  .SiteNavigation__link {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .SiteBrand__logo {
    max-width: 200px;
  }
  .SiteSearch {
    min-width: 46px;
    margin-right: 4px;
  }
  .SiteSearch__input {
    width: 46px;
  }
  .SiteSearch__input::placeholder {
    color: var(--white, #fff);
  }
  .SiteSearch__input:-ms-input-placeholder {
    color: var(--white, #fff);
  }
  .SiteSearch__input::-webkit-input-placeholder {
    color: var(--white, #fff);
  }
  .SiteSearch__input:hover::placeholder {
    color: var(--white, #fff);
  }
  .SiteSearch__input:not(:focus) + .SiteSearch__icon {
    left: 7px;
  }
  .SiteSearch__input:focus,
  .SiteSearch__input--focused {
    color: var(--gray10, #222);
  }
  .SiteSearch__input:focus::placeholder,
  .SiteSearch__input--focused::placeholder {
    color: var(--gray6, #999);
  }
  .SiteSearch__input:focus:-ms-input-placeholder,
  .SiteSearch__input--focused:-ms-input-placeholder {
    color: var(--gray6, #999);
  }
  .SiteSearch__input:focus::-webkit-input-placeholder,
  .SiteSearch__input--focused::-webkit-input-placeholder {
    color: var(--gray6, #999);
  }
  .SiteSearch__input--blurred {
    color: transparent;
  }
  .SiteManageNav__item {
    margin-left: 16px;
  }
}
.SiteFooter {
  background-color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 92px;
  position: relative;
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  color: var(--gray7, #686868);
  border: none;
  z-index: 1000;
}
.SiteFooter .SiteFooter__container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  transition-property: transform, opacity;
  -webkit-transition-duration: 0.4s, 0.1s;
  -moz-transition-duration: 0.4s, 0.1s;
  -o-transition-duration: 0.4s, 0.1s;
  -ms-transition-duration: 0.4s, 0.1s;
  transition-duration: 0.4s, 0.1s;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-delay: 0s, 0.3s;
  -moz-transition-delay: 0s, 0.3s;
  -o-transition-delay: 0s, 0.3s;
  -ms-transition-delay: 0s, 0.3s;
  transition-delay: 0s, 0.3s;
}
.SiteFooter .SiteFooter__container.SiteFooter__container--main {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  padding-top: 24px;
  border-top: 1px solid #e0e0e0;
  background-color: var(--white, #fff);
}
.SiteFooter .SiteFooter__container.SiteFooter__container--actions {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.SiteFooter .SiteFooter__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--outer {
  width: auto;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--outerReverse {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.SiteFooter .SiteFooter__link:not(:hover) {
  color: var(--gray7, #686868);
}
.SiteFooter .SiteFooter__link.SiteFooter__link--logo {
  fill: var(--gray7, #686868);
}
.SiteFooter .SiteFooter__link.SiteFooter__link--logo:hover {
  fill: var(--gray8, #555);
}
.SiteFooter .SiteFooter__infoAlign {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.SiteFooter.SiteFooter--open .SiteFooter__container.SiteFooter__container--actions {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0s, 0s;
  -moz-transition-delay: 0s, 0s;
  -o-transition-delay: 0s, 0s;
  -ms-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.SiteFooter__hidden {
  visibility: hidden;
}
.SiteFooter.SiteFooter--large-theme .SiteFooter__container.SiteFooter__container--actions {
  padding-top: 24px;
  padding-bottom: 32px;
}
.SiteFooter.SiteFooter--biggie-theme .SiteFooter__container.SiteFooter__container--actions {
  padding-top: 32px;
  padding-bottom: 48px;
}
.SiteFooter.SiteFooter--light-theme .SiteFooter__container.SiteFooter__container--actions {
  background-color: var(--gray1, #f4f4f4);
  border-top: 1px solid #e0e0e0;
}
.SiteFooter.SiteFooter--dark-theme .SiteFooter__container.SiteFooter__container--actions {
  background-color: var(--gray10, #222);
  -webkit-box-shadow: 0 -1px 4px 0 rgba(0,0,0,0.3);
  box-shadow: 0 -1px 4px 0 rgba(0,0,0,0.3);
}
.SiteFooter.SiteFooter--dark-theme .SiteFooter__container.SiteFooter__container--actions .SiteFooter__link.SiteFooter__link--logo {
  fill: var(--gray5, #adadad);
}
.SiteFooter.SiteFooter--dark-theme .SiteFooter__container.SiteFooter__container--actions .SiteFooter__link.SiteFooter__link--logo:hover {
  fill: var(--gray4, #ccc);
}
.SiteFooter.SiteFooter--dark-theme .SiteFooter__link.SiteFooter__link--logo {
  fill: var(--gray7, #686868);
}
.SiteFooter.SiteFooter--dark-theme .SiteFooter__link.SiteFooter__link--logo:hover {
  fill: var(--gray8, #555);
}
@media screen and (max-width: 1199px) {
  .SiteFooter {
    min-width: 100vw;
  }
  .SiteFooter .SiteFooter__container.SiteFooter__container--main {
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0;
    padding-top: 24px;
  }
  .SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--outerReverse {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--outer,
  .SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--outerReverse {
    min-width: 100%;
    padding: 0 50px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--hidden {
    display: inline;
  }
}
@media screen and (max-width: 740px) {
  .SiteFooter {
    height: 109px;
  }
  .SiteFooter .SiteFooter__container.SiteFooter__container--main {
    padding-top: unset;
  }
  .SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--outer {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
    -o-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--outer>.SiteFooter__wrapper.SiteFooter__wrapper--left {
    margin-top: 12px;
  }
  .SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--outerReverse {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 20px 0;
  }
  .SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--outerReverse>.SiteFooter__wrapper.SiteFooter__wrapper--right {
    margin-top: 12px;
  }
  .SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--left {
    width: 100%;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 20px;
  }
  .SiteFooter .SiteFooter__wrapper.SiteFooter__wrapper--hidden {
    display: none;
  }
  .SiteFooter .SiteFooter__action:not(.SiteFooter__action--primary) {
    display: none;
  }
  .SiteFooter .SiteFooter__action.SiteFooter__action--primary {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
  }
  .SiteFooter.SiteFooter--biggie-theme .SiteFooter__container.SiteFooter__container--actions {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.EmployeeTraining__attachedFilesLabel {
  color: var(--gray8, #555);
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
}
.EmployeeTraining__attachedFiles {
  display: block;
}
.EmployeeTraining__files {
  max-width: 500px;
}
.EmployeeTraining__files .file-wrapper:first-child {
  margin-top: 0;
}
.TaskManager {
  border-color: var(--gray4, #ccc);
  background: var(--gray1, #f4f4f4);
  padding: 16px 20px;
}
.TaskManager__details--completed .TaskManager__itemWrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.TaskManager__header {
  margin: 0 0 17px;
}
body[data-feature-toggles~="jade"] .TaskManager__header.TaskManager__header--complete {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 2px solid #ccc;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 16px 0 12px;
}
.TaskManager__header.TaskManager__header--complete.clearfix:after,
.TaskManager__header.TaskManager__header--complete.clearfix:before {
  content: none;
}
.TaskManager__brandedBar {
  background: var(--theme-base-default, #527a00);
  background: var(--fabric-theme-base, #527a00);
  border-radius: 2px 2px 0 0;
}
.TaskManager__taskList {
  border-color: var(--gray4, #ccc);
}
.TaskManager__title--complete {
  margin: 0;
}
.TaskManager__actions {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.TaskManager__actionWrapper {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.TaskManager__actionWrapper button {
  height: auto;
}
.TaskManager__summary {
  margin-top: 14px;
}
.TaskManager__summaryItem {
  color: var(--gray7, #686868);
  font-size: var(--font-size-small, 14px);
}
.TaskManager__jadeBlankIcon {
  fill: var(--gray5, #adadad);
  display: block;
  margin-bottom: 10px;
}
div.TaskManager__filter {
  margin-left: 12px;
  width: auto;
}
div.TaskManager__filter--small {
  width: auto;
  margin-left: 12px;
}
body[data-feature-toggles~="jade"] .TaskManager__emptyMsg {
  color: var(--gray7, #686868);
  font-size: var(--font-size-h3, 22px);
  font-weight: var(--font-weight-semibold, 600);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 177px;
}
body[data-feature-toggles~="jade"] .TaskManager__emptyMsg--complete {
  color: var(--gray7, #686868);
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-teenie, 18px);
  height: auto;
  padding: 11px 0 8px;
}
body[data-feature-toggles~="jade"] .TaskManager__detailsInfoItem {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 16px;
  padding: 0;
}
body[data-feature-toggles~="jade"] .TaskManager__detailsIcon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  position: unset;
  margin-right: 12px;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  -ms-transform: none;
  transform: none;
}
.TaskManager__itemWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 22px 29px 29px 24px;
}
body[data-feature-toggles~="jade"] .TaskManager__itemWrapper .itemDetail__title {
  padding: 1px 0 0;
  margin-right: 12px;
}
body[data-feature-toggles~="jade"] .TaskManager__itemWrapper .itemDetail__details {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  margin: 0;
}
body[data-feature-toggles~="jade"] .TaskManager__itemWrapper .FileCard--display {
  border-color: var(--gray4, #ccc);
  background-color: var(--gray1, #f4f4f4);
  border-radius: 2px;
  margin: 5px 16px 5px 0;
}
body[data-feature-toggles~="jade"] .TaskManager__itemWrapper .FileCard--display:hover {
  background-color: var(--gray2, #ebebeb);
}
body[data-feature-toggles~="jade"] .TaskManager__itemWrapper .FileCard__title {
  color: var(--info, #0772b3);
  font-family: unset;
}
body[data-feature-toggles~="jade"] .TaskManager__itemWrapper .FileCard__title:hover {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
body[data-feature-toggles~="jade"] .TaskManager__itemWrapper .FileCard__info {
  color: var(--gray7, #686868);
}
body[data-feature-toggles~="jade"] .TaskManager__itemWrapper .FileCard__image {
  margin-right: 5px;
}
.TaskManager__itemTitle {
  color: var(--gray10, #222);
  margin: 0 0 10px;
}
.TaskManager__itemTitle--complete {
  color: var(--gray7, #686868);
}
.TaskManager__detailsIconText {
  font-weight: var(--font-weight-semibold, 600);
  margin: 0;
}
.TaskManager__detailsIconText.TaskManager__detailsIconLink {
  color: var(--info, #0772b3);
}
.TaskManager__detailsIconText.TaskManager__detailsIconLink:hover {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.TaskManager__itemFrequency {
  color: var(--gray8, #555);
  font-weight: var(--font-weight-regular, 400);
}
.TaskManager__detailsDescription {
  color: var(--gray8, #555);
  white-space: pre-line;
  margin: 0 0 20px 0;
}
body[data-feature-toggles~="jade"] .TaskManager__filesList {
  border-color: var(--gray3, #e0e0e0);
  margin: 0;
  padding-top: 24px;
  width: calc(100% - 35px);
}
body[data-feature-toggles~="jade"] .TaskManager__detailsInfo {
  margin-bottom: 24px;
}
body[data-feature-toggles~="jade"] .TaskManager__completeInfo {
  border-radius: 2px;
  border: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  background-color: var(--gray2, #ebebeb);
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 0 24px;
  padding: 20px 20px 22px 50px;
  width: 100%;
}
body[data-feature-toggles~="jade"] .TaskManager__completeInfoItems {
  padding-top: 0;
}
body[data-feature-toggles~="jade"] .TaskManager__completeInfoItem {
  margin: 16px 0 0;
}
body[data-feature-toggles~="jade"] .TaskManager__completeInfoItem--notes {
  padding-top: 16px;
  margin: 0;
}
body[data-feature-toggles~="jade"] .TaskManager__completeInfoItemLabel {
  color: var(--gray8, #555);
  line-height: var(--line-height-teenie, 18px);
}
.TaskManager__completeInfoItemValue {
  word-break: break-all;
  white-space: normal;
}
.TaskManager__completeIcon {
  fill: var(--success, #0e840d);
  padding-top: 2px;
  margin-right: 8px;
}
.TaskManager__completeTitle {
  color: var(--success, #0e840d);
  margin: 0;
}
body[data-feature-toggles~="jade"] .TaskManager__completeTitle {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  position: relative;
  right: 30px;
}
.TrainingList {
  position: relative;
}
.TrainingList:first-child .TrainingList__category {
  border-top-right-radius: 0;
}
.TrainingList__category {
  font-size: var(--font-size-teenie, 13px);
  background-color: var(--gray2, #ebebeb);
  color: var(--gray9, #444);
  border: none;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  position: absolute;
}
body[data-feature-toggles~="jade"] .TrainingList__category + li.List__item {
  padding-top: 33px;
}
.TrainingList__item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 13px 17px 13px 14px;
}
.TrainingList__item + .List__item-note {
  margin-left: 8px;
}
.TrainingList__itemInfoWrapper {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.TrainingList__upcomingCheckbox {
  margin: 0 8px 2px 0;
}
body[data-feature-toggles~="jade"] .TrainingList__tasksCompleted.tasks-completed {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 7px;
  padding: 7px 0 19px;
}
body[data-feature-toggles~="jade"] .TrainingList__tasksCompleted.tasks-completed:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
body[data-feature-toggles~="jade"] .TrainingList__tasksCompleted.tasks-completed .List__category {
  font-size: var(--font-size-teenie, 13px);
  font-weight: var(--font-weight-semibold, 600);
  background-color: unset;
}
body[data-feature-toggles~="jade"] .TrainingList__tasksCompleted.tasks-completed .List__category + .List__item {
  margin-top: 21px;
  padding-top: 0;
}
body[data-feature-toggles~="jade"] .TrainingList__tasksCompleted.tasks-completed .List__item-note {
  color: var(--gray7, #686868);
}
body[data-feature-toggles~="jade"] .TrainingList__tasksCompleted.tasks-completed .List__item-title:hover {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
body[data-feature-toggles~="jade"] .TrainingList__tasksCompleted.tasks-completed .List__item--divider {
  border-bottom: none;
  margin: 10px 0 0;
}
body[data-feature-toggles~="jade"] .TrainingList__tasksCompleted.tasks-completed .TrainingList__item {
  position: relative;
}
.TrainingList__utilityIconWrapper {
  bottom: calc(50% - 17px);
  position: absolute;
  right: 0;
}
.TrainingItem__contentContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
body[data-feature-toggles~="jade"] .TrainingItem__contentWrapper {
  margin: 0 0 0 10px;
}
body[data-feature-toggles~="jade"] .tabContent .tabTrainingWrapper.tabHeader {
  padding: 17px 0 11px;
}
.tabContent .jadeTrainingIcon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  margin-right: 8px;
}
body[data-feature-toggles~="jade"] .tabContent .tabTrainingIcon {
  margin: 0;
  padding-top: 0;
}
body[data-feature-toggles~="jade"] .tabContent .tabTrainingIcon:before {
  content: none;
}
.SortList {
  line-height: 1;
}
.SortList__list {
  min-height: 37px;
}
.SortList__Message,
.ui-dialog .SortList__Message {
  font-family: "BhrHeaderFont", "Trebuchet MS";
  padding: 0 20px 0 !important;
  margin-bottom: 8px !important;
  color: #777;
  font-size: 17px;
}
body[data-feature-toggles~="jade"] .SortList__Message {
  font-family: unset;
  color: inherit;
  font-size: initial;
}
.SortList__listItem {
  background: #f7f7f7;
  border: 1px dashed #b0b0b0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  cursor: move;
  font-family: "BhrHeaderFont", "Trebuchet MS";
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0 0 !important;
  padding: 10px 10px !important;
  position: relative;
  width: 400px;
/* Pretty transitions */
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
/* Disable Selection of text on items */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.SortList__listItem-editable {
  padding: 2px 4px;
  margin: 5px 8px;
}
.SortList__listItem.edit .SortList__listItem-editable {
  -webkit-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.SortList__listItem-editable:focus {
  background-color: #efefef;
  -webkit-box-shadow: 0 0 1px 0 #d2d2d2;
  box-shadow: 0 0 1px 0 #d2d2d2;
  cursor: text;
  -o-text-overflow: clip;
  text-overflow: clip;
}
.SortList__listItem-remove {
  background-color: #fff;
  color: #929292;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #929292;
  position: absolute;
  top: -3px;
  right: -3px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  z-index: 1;
}
.SortList__listItem-remove:hover {
  cursor: pointer;
}
.SortList__listItem-remove span {
  width: 14px;
  display: block;
  text-align: center;
  position: relative;
  top: -3px;
  font-size: 14px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.SortList__listItem:not(.js-SortList__listItem--disabled):hover {
  background-color: #e6e6e6;
}
.SortList__listItem:not(.js-SortList__listItem--disabled):active {
  background-color: #fff;
}
.SortList__sortSection {
  position: relative;
  padding: 8px 10px;
  margin: 0 0 11px;
  border: 1px solid transparent;
  max-width: 650px;
}
.SortList__sortSection ~ .SortList__sortSection:before {
  background-color: #d4d4d4;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: -7px;
  width: 100%;
}
.SortList__sortSection:hover:not(:only-child) {
  border: 1px dashed #ccc;
  cursor: move;
}
.SortList__sortSection-headerWrapper {
  position: relative;
}
.SortList__sortSection-title {
  color: #999;
  font-family: "BhrHeaderFont", "Trebuchet MS";
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
.SortList__sortSection:hover .SortList__sortSection-title,
.SortList__sortSection.ui-sortable-helper .SortList__sortSection-title {
  color: #222;
}
.SortList__sortSection.ui-sortable-placeholder {
  height: 30px !important;
  visibility: visible !important;
}
.SortList--greenHouse [class~="inline-bicon-"]:hover,
.SortList--greenHouse [class^="inline-bicon-"]:hover {
  background-color: #fff;
}
.SortList--greenHouse .SortList__list {
  min-height: 55px;
}
.SortList--greenHouse .SortList__listItem.align {
  padding: 14px 13px 2px !important;
}
.SortList__listItem-label {
  display: inline-block;
  max-width: 76%;
  line-height: 20px;
  margin-top: -2px;
}
.SortList__listItem-type {
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-weight: normal;
  display: block;
  color: #999;
  font-size: 12px;
  height: 12px;
  text-transform: capitalize;
}
.SortList__sortSection-actions {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  right: 5px;
  top: 5px;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.SortList__listItem-actions,
.SortList__sortSection-actions {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  right: 5px;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.SortList__listItem-actions:hover,
.SortList__sortSection-actions:hover {
  cursor: default;
}
.SortList__listItem-actions {
  top: 50%;
  margin-top: -10px;
}
.SortList__sortSection-actions {
  top: 5px;
}
.SortList--greenHouse .SortList__listItem:hover .SortList__listItem-actions,
.SortList--greenHouse .SortList__sortSection:hover .SortList__sortSection-actions {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.SortList--greenHouse .SortList__listItem.ui-sortable-helper .SortList__listItem-actions,
.SortList--greenHouse .SortList__sortSection.ui-sortable-helper .SortList__sortSection-actions {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.SortList--greenHouse .SortList__listItem {
  padding: 8px 13px !important;
  font-size: 16px;
  height: 30px;
}
.Sortlist--inline {
  min-width: 600px;
  margin: -20px 0 0 -8px;
}
.Sortlist--inline .reorderTabs1 {
  float: left;
  width: auto;
}
.Sortlist--inline .SortList__listItem {
  background-color: #fff;
  margin-right: 3px !important;
  width: auto;
  float: left;
  max-width: 250px;
  padding: 0 0 !important;
}
.Sortlist--inline .SortList__listItem.edit {
  background-color: #e6e6e6;
}
.Sortlist--inline .SortList__listItem.ui-sortable-helper {
  width: auto !important;
  height: 29px !important;
}
.Sortlist--inline .js-SortList__placeholder {
  height: 34px !important;
  padding: 0 !important;
  visibility: visible;
}
.Sortlist--inline .reorderTabs1 .js-SortList__placeholder {
  float: left;
}
.Sortlist--inline-more {
  float: right;
  position: relative;
}
.Sortlist--inline-more__title {
  background-color: #ebebeb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #909090;
  display: block;
  margin-top: 7px;
  position: relative;
  text-align: center;
  width: 106px;
  padding: 9px 10px 8px;
}
.Sortlist--inline-more__title:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #909090;
  content: '';
  height: 0;
  left: 2px;
  position: relative;
  top: 12px;
  width: 0;
}
.Sortlist--inline .reorderTabs2 {
  border: 1px solid #919191;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  clear: both;
  float: right;
  margin: 4px 0;
  padding: 0 3px 0 3px;
  width: 125px;
}
.Sortlist--inline .reorderTabs2 .SortList__listItem {
  float: none;
  margin: 3px 0 !important;
}
.alt .js-SortList__listItem--disabled {
  border: 1px solid transparent;
}
.alt .SortList__listItem:not(.js-SortList__listItem--disabled):active,
.alt .SortList__listItem.ui-sortable-helper {
  background-color: #e6e6e6; /* Fallback*/
  background-color: rgba(225,225,225,0.85);
}
.alt .SortList__listItem.ui-sortable-helper {
  border: 1px dashed #b0b0b0;
}
.alt .SortList__Message {
  margin-top: 20px;
  float: left;
  font-size: 16px;
}
.alt .reorderTabs2 {
  -webkit-box-shadow: 0 3px 3px -1px #ddd;
  -webkit-box-shadow: 0 3px 3px -1px #ddd;
  box-shadow: 0 3px 3px -1px #ddd;
}
.js-SortList__listItem--disabled {
  color: #909090;
}
.js-SortList__listItem--disabled:hover {
  cursor: default;
}
.js-SortList__sortSection--disabled:hover {
  cursor: default;
}
.js-SortList__placeholder {
  visibility: hidden;
  height: 36px !important;
}
.SortList--greenHouse .js-SortList__placeholder {
  height: 56px !important;
}
.SortList__listItem.ui-sortable-helper {
  border: 1px solid #999;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: 0 6px 5px -5px #bbb;
  box-shadow: 0 6px 5px -5px #bbb;
  -webkit-box-shadow: 0 6px 5px -5px #bbb;
  cursor: pointer;
  overflow: hidden;
}
.SortList__sortSection.ui-sortable-helper {
/*background-color: #fff;
	border: 1px solid #999;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 0px 6px 5px -5px #bbb;
	-webkit-box-shadow: 0px 6px 5px -5px #bbb;
	color: #222;*/
  border: none;
  cursor: pointer;
  height: 25px !important;
  overflow: hidden;
  color: #f00;
}
.SortList__sortSection.ui-sortable-helper:hover {
  border: none;
}
.SortList__sortSection.ui-sortable-helper .SortList__sortSection-title {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: 0 6px 5px -5px #bbb;
  box-shadow: 0 6px 5px -5px #bbb;
  padding: 6px 10px 6px 30px;
  margin-bottom: 5px;
  min-height: 14px;
}
.SortList__sortSection.ui-sortable-helper .draggable-icon {
  display: block;
}
.draggable-icon {
  border-top: 3px solid #797979;
  display: none;
  height: 15px;
  left: 20px;
  position: absolute;
  top: 15px;
  width: 15px;
}
.draggable-icon:after,
.draggable-icon:before {
  background-color: #c4c4c4;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  right: 0;
  width: 12px;
}
.draggable-icon:after {
  top: 2px;
}
.draggable-icon:before {
  top: 7px;
}
.SortList__tabsMessage {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 0 8px;
  padding: 3px 15px;
  text-align: center;
  width: 100%;
}
.SortList__tabsMessage .CurvedArrow {
  height: 27px;
  margin-right: 10px;
  top: 0;
  width: 27px;
}
.SortList__tabsMessage .CurvedArrow:before {
  border-top-width: 15px;
  border-right-width: 15px;
  border-right-color: var(--theme-base-default, #527a00);
  border-right-color: var(--fabric-theme-base, #527a00);
}
.SortList__tabsMessage .CurvedArrow:after {
  border-top-color: var(--theme-base-default, #527a00);
  border-top-color: var(--fabric-theme-base, #527a00);
  border-top-width: 8px;
  top: 0;
  height: 13px;
  width: 18px;
}
.SortList__tabsMessage-fields {
  color: var(--gray10, #222);
  margin: 14px 0 10px;
}
.SortList__sortSection-title {
  color: var(--gray7, #686868);
  font-size: var(--font-size-large, 16px);
  font-weight: var(--font-weight-semibold, 600);
  line-height: var(--line-height-medium, 22px);
  font-family: unset;
}
.SortList__sectionIcon {
  fill: var(--gray7, #686868);
  display: none;
}
.SortList__sortSection {
  padding: 7px 13px 13px;
  margin: 0;
}
.SortList__sortSection ~ .SortList__sortSection {
  margin-top: 21px;
}
.SortList__sortSection ~ .SortList__sortSection:before {
  background-color: var(--gray3, #e0e0e0);
  left: 13px;
  top: -12px;
  width: calc(100% - 26px);
}
.SortList__sortSection .SortList__list {
  min-height: unset;
}
.SortList__sortSection.ui-sortable-helper {
  display: block;
}
.SortList__sortSection.ui-sortable-helper .SortList__sectionIcon {
  fill: var(--gray7, #686868);
  display: block;
  margin-right: 10px;
}
.SortList__sortSection.ui-sortable-helper .SortList__sortSection-title {
  font-size: var(--font-size-medium, 15px);
  border-color: var(--gray6, #999);
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 2px;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-shadow: 0 1px 3px rgba(153,153,153,0.7);
  box-shadow: 0 1px 3px rgba(153,153,153,0.7);
  padding: 7px 6px 7px 10px;
}
.SortList__sortSection .SortList__listItem {
  background-color: var(--gray1, #f4f4f4);
  border-color: var(--gray4, #ccc);
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px 12px 7px !important;
  width: 100%;
}
.SortList__sortSection .SortList__listItem:hover:not(.js-SortList__listItem--disabled) {
  background-color: var(--gray3, #e0e0e0);
  border-color: var(--gray6, #999);
}
.SortList__sortSection .SortList__listItem.ui-sortable-helper {
  border-style: dashed;
}
.SortList__sortSection .SortList__listItem.js-SortList__listItem--disabled {
  background-color: var(--white, #fff);
  color: var(--gray5, #adadad);
  border: none;
  padding: 0 12px 0 0 !important;
}
.SortList__listItem {
  border-radius: 2px;
  font-family: unset;
}
.SortList__listItem-editable {
  color: var(--gray10, #222);
  font-size: var(--font-size-medium, 15px);
  font-weight: var(--font-weight-semibold, 600);
  line-height: var(--line-height-medium, 22px);
  font-family: unset;
  margin: 0;
  padding: 6px 16px 8px;
}
.Sortlist--inline {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 100%;
  margin: 0;
}
.Sortlist--inline .reorderTabs1 {
  padding: 36px 0 0 100px;
}
.Sortlist--inline-more {
  padding: 36px 100px 0 0;
}
.Sortlist--inline-more__title {
  background-color: var(--gray1, #f4f4f4);
  color: var(--gray10, #222);
  line-height: var(--line-height-medium, 22px);
  border: 1px solid #f4f4f4;
  border-radius: 2px;
  margin-top: 8px;
  padding: 6px 16px 8px;
  width: auto;
}
.Sortlist--inline .SortList__listItem {
  background-color: var(--gray1, #f4f4f4);
  border-color: var(--gray4, #ccc);
  margin-right: 12px !important;
}
.Sortlist--inline .SortList__listItem:hover:not(.js-SortList__listItem--disabled) {
  background-color: var(--gray3, #e0e0e0);
  border-color: var(--gray6, #999);
}
.Sortlist--inline .SortList__listItem.ui-sortable-helper {
  height: 36px !important;
}
.Sortlist--inline .SortList__verticalListWrapper {
  border-color: var(--fabric-theme-lighter, #82af13);
  -webkit-box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  outline: 0;
  border-radius: 2px;
  margin: 10px 100px 4px 0;
  padding: 17px;
  position: relative;
  width: auto;
}
.Sortlist--inline .SortList__verticalListWrapper .SortList__listItem {
  margin: 0 0 6px 0 !important;
}
.Sortlist--inline .SortList__verticalListWrapper .SortList__listItem.ui-sortable-helper {
  width: calc(100% - 34px) !important;
}
.Sortlist--inline .SortList__verticalListWrapper .SortList__listItem.js-SortList__listItem--disabled {
  margin: 0 !important;
}
.Sortlist--inline .SortList__verticalListWrapper .SortList__listItem:not(.js-SortList__listItem--disabled) + .js-SortList__listItem--disabled {
  margin-top: 8px !important;
}
.Sortlist--inline .SortList__verticalListWrapper .SortList__listItem:last-child {
  margin: 0 !important;
}
.alt .js-SortList__listItem--disabled {
  background-color: transparent;
  border: none;
  margin: 0 !important;
}
.alt .js-SortList__listItem--disabled .SortList__listItem-editable {
  color: var(--gray5, #adadad);
  font-weight: var(--font-weight-regular, 400);
  padding: 6px 16px 6px 0;
}
.alt .js-SortList__listItem--disabled:last-child .SortList__listItem-editable {
  padding: 6px 16px 0 0;
}
