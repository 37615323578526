for $i, $theme in $fabThemes {
	$themeAttr = '[ba-theme="' + $theme.name + '"]';
	&{$themeAttr} {
		--fabric-theme-base: $theme.base;
		--fabric-theme-light: $theme.light;
		--fabric-theme-lighter: $theme.lighter;
		--fabric-theme-lightest: $theme.lightest;
		--fabric-theme-lightest10: rgba($theme.lightest, 0.1);
		--fabric-theme-lightest35: rgba($theme.lightest, 0.35);
		--fabric-theme-lightest50: rgba($theme.lightest, 0.5);
	}
}

// @startEncoreCleanup
body[data-fabric-theme~="encore"] {
	for $i, $theme in $fabEncoreThemes {
		$themeAttr = '[ba-theme="' + $theme.name + '"]';
		&{$themeAttr}, & {$themeAttr} {
			--fabric-theme-base: $theme.base;
			--fabric-theme-dark: $theme.dark;
			--fabric-theme-light: $theme.light;
			--fabric-theme-lighter: $theme.lighter;
			--fabric-theme-lightest: $theme.lightest;
			--fabric-theme-lightest10: rgba($theme.lightest, 0.1);
			--fabric-theme-lightest35: rgba($theme.lightest, 0.35);
			--fabric-theme-lightest50: rgba($theme.lightest, 0.5);
		}
	}
}
// @endEncoreCleanup

// JADE TODO: Remove ui rules when we depricate jQuery UI datepicker
#ui-datepicker-div .ui-datepicker-calendar .ui-state-active {
	fab-background-color: theme-base;
}

.ui-widget {
	font-family: inherit;
}
